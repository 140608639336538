<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="财务看板"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="FinancialBoard" style="padding-top: 46px">
    <h5>报价</h5>
    <van-cell-group inset>
      <van-cell
        title="项目累积报价总额"
        :value="
          resultData.projectBidAmountAll == null
            ? '0元'
            : resultData.projectBidAmountAll + '元'
        "
      />
      <van-cell
        title="执行中项目报价总额"
        :value="
          resultData.executionProjectBidAmountAll == null
            ? '0元'
            : resultData.executionProjectBidAmountAll + '元'
        "
      />
      <van-cell
        title="已完工项目报价总额"
        :value="
          resultData.completionProjectBidAmountAll == null
            ? '0元'
            : resultData.completionProjectBidAmountAll + '元'
        "
      />
      <van-cell
        title="未报价项目数"
        :value="returnInt(resultData.noQuotationProjectNumber) + '个'"
      />
      <van-cell
        title="已开业未报价项目数"
        :value="returnInt(resultData.openedNoQuotationProjectNumber) + '个'"
      />
    </van-cell-group>
    <h5>发票</h5>
    <van-cell-group inset>
      <van-cell
        title="已开票项目数"
        :value="returnInt(resultData.hasInvoiceProjectNumber) + '个'"
      />
      <van-cell
        title="未开票项目数"
        :value="returnInt(resultData.notInvoiceProjectNumber) + '个'"
      />
    </van-cell-group>
    <h5>回款</h5>
    <van-cell-group inset>
      <van-cell
        title="已开票已回款总额"
        :value="returnInt(resultData.hasInvoiceReceivableAmountAll) + '元'"
      />
      <van-cell
        title="已开票未回款总额"
        :value="returnInt(resultData.hasInvoiceNotReceivableAmountAll) + '元'"
      />
      <van-cell
        title="回款比例"
        :value="returnInt(resultData.receivableProportion)"
      />
    </van-cell-group>
    <h5>押金</h5>
    <van-cell-group inset>
      <van-cell
        title="押金已回款总额"
        :value="returnInt(resultData.depositHasReceivable) + '元'"
      />
      <van-cell
        title="押金未回款总额"
        :value="returnInt(resultData.depositNotReceivable) + '元'"
      />
      <van-cell
        title="押金回款比例"
        :value="returnInt(resultData.depositReceivableProportion)"
      />
    </van-cell-group>
    <h5>成本</h5>
    <van-cell-group inset>
      <van-cell
        title="成本支出总额"
        :value="
          resultData.costSpendingAmountAll == null
            ? '0元'
            : resultData.costSpendingAmountAll + '元'
        "
      />
    </van-cell-group>
    <br />
    <br />
  </div>
</template>

<script>
import { FinanBoard } from "../../api";
import { Toast } from "vant";
export default {
  name: "FinancialBoard",
  data() {
    return {
      resultData: "",
    };
  },
  methods: {
    returnInt(int) {
      if (int == null) {
        return 0;
      } else {
        return int;
      }
    },
    // 返回
    onClickBack() {
      // 跳转上一级。
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        console.log(222);
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    getDetails() {
      FinanBoard({
        token: this.$route.query[0],
        userid: this.$route.query[1],
        roleid: this.$route.query[3],
      })
        .then((result) => {
          this.resultData = result.data;
          console.log(this.resultData.projectBidAmountAll);
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    minuteToDay(min) {
      let minTime = "";
      let d = Math.floor(min / (60 * 24));
      let h = Math.floor((min - d * 24 * 60) / 60);
      min = min - d * 24 * 60 - h * 60;
      if (d > 0) {
        minTime += d + "天";
      }
      if (h > 0) {
        minTime += h + "时";
      }
      if (min > 0) {
        minTime += min + "分";
      }
      return minTime == "" ? "0" : minTime;
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 15px;
}
</style>
