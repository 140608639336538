<template>
  <div></div>
</template>

<script>
// import { Toast } from "_vant@3.4.5@vant";
export default {
  // 钩子
  mounted: function () {
    window.getDataFromNative = this.getDataFromNative;
    // if (this.userList) {
    //   this.$router.push({
    //     // path: "/homePage",
    //     // query: this.userList,
    //     path: "/AcceptanceProList",
    //     query: this.userList,
    //     // path: "/StoreView",
    //     // query: this.userList,
    //   });
    //   // this.$router.push({
    //   //   path: "/TurnSendList",
    //   //   query: this.userList,
    //   // });
    // }
  },
  data() {
    return {
      // userList: [
      //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI2IiwiZXhwIjoyMDM0NDY5OTk2LCJpYXQiOjE3MTg5MzcxOTZ9.fxgMpgjM3b1Ssg7P9qmlhpX1CUUus96hlb3xjer0Jfc",
      //   6,
      //   10215,
      //   4,
      // ],
      userList: [],
      content: 0,
    };
  },
  methods: {
    getDataFromNative(params) {
      this.userList = params.split(",");
      // var data = {
      //   name: "xiaomili",
      // };
      // return data; //回调给原生，可写可不写
      if (this.userList && this.content == 0) {
        this.content++;
        switch (this.userList[2]) {
          case "106103":
            this.$router.push({
              path: "/AttendanceBoard",
              query: this.userList,
            });
            break;
          case "106102":
            this.$router.push({
              path: "/ProjectBoard",
              query: this.userList,
            });
            break;
          case "106101":
            this.$router.push({
              path: "/FinancialBoard",
              query: this.userList,
            });
            break;
          case "102101":
            this.$router.push({
              path: "/ProjectInformation",
              query: this.userList,
            });
            break;
          case "102102":
            this.$router.push({
              path: "/StoreInformation",
              query: this.userList,
            });
            break;
          case "102103":
          case "102109":
            this.$router.push({
              path: "/SupplierInformation",
              query: this.userList,
            });
            break;
          case "102104":
            this.$router.push({
              path: "/PartyasInformation",
              query: this.userList,
            });
            break;
          case "102105":
            this.$router.push({
              path: "/PartyasInformation",
              query: this.userList,
            });
            break;
          case "102106":
            this.$router.push({
              path: "/ContractInformation",
              query: this.userList,
            });
            break;
          case "102107":
            this.$router.push({
              path: "/InvoiceInformation",
              query: this.userList,
            });
            break;
          case "104105":
            this.$router.push({
              path: "/ExpenditureStatisticsList",
              query: this.userList,
            });
            break;
          case "104106":
            this.$router.push({
              path: "/RemittanceStatistics",
              query: this.userList,
            });
            break;
          case "105102":
          case "205":
            this.$router.push({
              path: "/ContractReviewList",
              query: this.userList,
            });
            break;
          case "105103":
          case "206":
            this.$router.push({
              path: "/InvoiceReviewList",
              query: this.userList,
            });
            break;
          case "105104":
          case "207":
            this.$router.push({
              path: "/SupportAuditList",
              query: this.userList,
            });
            break;
          case "999":
            this.$router.push({
              path: "/UseGuide",
              query: this.userList,
            });
            break;
          case "102110":
            this.$router.push({
              path: "/AcceptanceProList",
              query: this.userList,
            });
            break;
        }
      }
    },
  },
};
</script>

<style></style>
