<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="发票详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="InvoiceDetails" style="padding-top: 46px">
    <br />
    <span
      style="
        text-align: center;
        display: block;
        color: rgb(150, 151, 153);
        font-size: 14px;
      "
      >发票金额:{{ parseFloat(totalAmount).toFixed(2) }}元</span
    >
    <h5>合同信息</h5>
    <van-cell-group
      class="cell-group"
      inset
      v-if="infoContract == null"
      @click="jumpContract(infoContract.id)"
    >
      <van-cell> <span style="font-size: 14px"> 暂无 </span></van-cell>
    </van-cell-group>
    <van-cell-group
      class="cell-group"
      inset
      v-if="infoContract != null"
      @click="jumpContract(infoContract.id)"
    >
      <van-cell
        ><span
          style="color: #e57e05; font-size: 14px"
          v-if="infoContract.contractName != null"
        >
          {{ infoContract.contractName }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="infoContract.type != null">
          {{ returnContract(infoContract.type) }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span
          style="font-size: 14px; margin-left: 0px"
          v-if="infoContract.partybName != null"
        >
          {{ infoContract.partybName }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="infoContract.taxAmount != null">
          {{ infoContract.taxAmount }}元
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="infoContract.minDate != null">
          {{ infoContract.minDate }}至{{ infoContract.maxDate }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="contractStatus != null">
          {{ contractStatus }}
        </span></van-cell
      >
    </van-cell-group>
    <h5>项目信息</h5>
    <van-cell-group
      class="cell-group"
      inset
      v-if="infoContract != null"
      @click="jumpProject(projInfo.id)"
    >
      <van-cell
        ><span style="color: #e57e05; font-size: 14px">
          {{
            "【" +
            returnType(projInfo.relationFlag) +
            "】" +
            projInfo.projectCode
          }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span
          style="font-size: 14px; margin-left: 0px"
          v-if="contractStatus != null"
        >
          {{ returnNull(projInfo.projectName) }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="projInfo.departmentName != null">
          {{ returnNull(projInfo.departmentName) }}
          {{ returnNull(projInfo.projectType) }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="projInfo.executeStatus != null">
          {{ getOpenType(projInfo.executeStatus) }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="headUser.truename != null">
          品牌负责人:{{ returnNull(headUser.truename) }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px" v-if="operationManagerUser != null"
        ><span style="font-size: 14px; margin-left: 0px">
          项目经理:{{ returnNull(operationManagerUser) }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px" v-if="perationResponsibleUser != null"
        ><span style="font-size: 14px; margin-left: 0px">
          运营负责人:{{ returnNull(perationResponsibleUser) }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px" v-if="desigrResponsibleUser != null"
        ><span style="font-size: 14px; margin-left: 0px">
          设计负责人:{{ returnNull(desigrResponsibleUser) }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="projInfo.receiptTime != null">
          下制作单时间:{{ formatDate(projInfo.receiptTime) }}
        </span></van-cell
      >
    </van-cell-group>
    <h5>报价信息</h5>
    <van-cell-group inset>
      <van-cell v-if="infoQuote == null">暂无</van-cell>
      <van-cell>
        <span
          style="color: #e57e05; font-size: 14px; margin-left: 0px"
          v-if="infoQuote.status == 3"
        >
          {{ "【" + returnStatus(infoQuote.status) + "】" + infoQuote.po }}
        </span>
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="infoQuote.status != 3"
        >
          {{ "【" + returnStatus(infoQuote.status) + "】" + infoQuote.po }}
        </span></van-cell
      >
      <van-cell v-if="infoQuote.amountTax != null"
        >税后金额:{{ returnNull(infoQuote.amountTax) }}元</van-cell
      >
      <van-cell v-if="infoQuote.tax != null"
        >税率:{{ infoQuote.tax }}%</van-cell
      >
      <van-cell v-if="infoQuote.amount != null"
        >不含税金额:{{ infoQuote.amount }}元</van-cell
      >
      <van-cell v-if="infoQuote.createUser != null"
        >报价人:{{ infoQuote.createUser }}</van-cell
      >
      <van-cell v-if="infoQuote.createTime != null"
        >报价时间:{{ formatDate(infoQuote.createTime) }}</van-cell
      >
      <van-cell v-if="infoQuote.remark != null"
        >开票理由:{{ returnNull(infoQuote.remark) }}</van-cell
      >
    </van-cell-group>
    <h5>发票信息</h5>
    <van-cell-group inset>
      <van-cell v-for="item in invoiceList" :key="item">
        <span
          style="color: #e57e05; font-size: 14px; margin-left: 0px"
          v-if="item.invoiceNum != null"
        >
          发票号:{{ item.invoiceNum }}
        </span>
        <br />
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.invoiceTaxAmount != null"
        >
          发票金额:{{ item.invoiceTaxAmount }}元</span
        >
        <br />
        <span style="font-size: 14px; margin-left: 0px" v-if="item.tax != null">
          税率:{{ item.tax }}%</span
        >
        <br /><span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.invoiceAmount != null"
        >
          不含税金额:{{ item.invoiceAmount }}元</span
        >
        <br /><span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.invoiceAmount != null"
        >
          开票人:{{ item.createUser }}</span
        >
        <br /><span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.createTime != null"
        >
          开票时间:{{ item.invoiceDate }}</span
        >
        <br />
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.remark != null"
        >
          备注:{{ returnNull(item.remark) }}</span
        >
        <br
      /></van-cell>
    </van-cell-group>
    <br />
    <br />
  </div>
</template>

<script>
import { InvoDetails } from "../../api";
import { Toast } from "vant";
export default {
  name: "ProjectDetails",
  data() {
    return {
      projInfo: "",
      totalAmount: 0,
      infoContract: "",
      contractStatus: "",
      infoQuote: {},
      headUser: {},
      operationManagerUser: "",
      desigrResponsibleUser: "",
      perationResponsibleUser: "",
      plantManagerUser: "",
      invoiceList: [],
    };
  },
  methods: {
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "" || data === null) {
        return "暂无";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
        );
      }
    },
    returnStatus(status) {
      switch (status) {
        case 1:
          return "未开票";
        case 2:
          return "已申请";
        default:
          return "已开票";
      }
    },
    getOpenType(type) {
      switch (type) {
        case 1:
          return "未开业";
        case 2:
          return "已开业";
        case 3:
          return "整改中";
        case 4:
          return "质保内";
        case 5:
          return "质保外";
        case 6:
          return "已完工";

        default:
          break;
      }
    },

    returnContract(int) {
      switch (int) {
        case 1:
          return "框架合同";
        case 2:
          return "单独合同（标准）";
        case 3:
          return "单独合同（非标）";
        default:
          break;
      }
    },
    returnType(int) {
      switch (int) {
        case 1:
          return "子单";
        case 2:
          return "母单";
        default:
          return "原单";
      }
    },
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
    getDetails() {
      InvoDetails({
        token: this.$route.query.token,
        userid: this.$route.query.userid,
        id: this.$route.query.id,
        invoiceorquote: this.$route.query.invoice == "报价" ? 0 : 1,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          // if (result.data.length > 0) {
          if (result.code == 1) {
            this.projInfo = result.data.projInfo;
            this.infoContract = result.data.infoContract;
            this.contractStatus = result.data.contractStatus;
            this.infoMarket = result.data.infoMarket;
            this.infoQuote = result.data.infoQuote;
            this.headUser = result.data.headUser;
            this.operationManagerUser = result.data.operationManagerUser;
            this.desigrResponsibleUser = result.data.desigrResponsibleUser;
            this.perationResponsibleUser = result.data.perationResponsibleUser;
            this.invoiceList = result.data.invoiceList;
            if (this.invoiceList != null) {
              for (let index = 0; index < this.invoiceList.length; index++) {
                this.totalAmount += this.invoiceList[index].invoiceTaxAmount;
              }
            } else {
              this.totalAmount = 0;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    jumpProject(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.executeStatus = this.getOpenType(this.projInfo.executeStatus);
      listData.id = id;
      this.$router.push({
        path: "/ProjectDetails",
        query: listData,
      });
    },
    jumpContract(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.contractId = id;
      this.$router.push({
        path: "/ContractDetails",
        query: listData,
      });
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 10px;
}
.cell-group {
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
}
.span-left {
  font-size: 14px;
  margin-left: 0px;
}
.span-right {
  font-size: 14px;
  margin-left: 40px;
  float: right;
}
</style>
