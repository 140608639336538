<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="合同信息"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="ContractInformation" style="padding-top: 46px">
    <van-search
      v-model="search"
      background="#f3f3f3"
      show-action
      placeholder="请输入合同名称搜索"
      @search="onSearch"
    >
      <template #action>
        <div @click="showPicker = true" style="color: rgb(150, 151, 153)">
          筛选
        </div>
      </template>
    </van-search>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div v-show="total > 0">
          <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >合同合计:{{ total }}</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <div>
            <span style="color: #e57e05">【{{ item.contractName }}】</span>
            <br />
            <span>{{ returnContract(item.type) }}</span>
            <br />
            <span>{{ item.companyName }}</span>
            <br />
            <span>{{ item.taxAmount }}元</span>
            <br />
            <span
              >{{ formatDate(item.minDate) }}至{{
                formatDate(item.maxDate)
              }}</span
            >
            <br />
            <span style="color: red" v-if="item.contractStatus == '已过期'">{{
              item.contractStatus
            }}</span>
            <span
              style="color: #e57e05"
              v-if="item.contractStatus == '即将过期'"
              >{{ item.contractStatus }}</span
            >
            <span
              v-if="
                item.contractStatus != '即将过期' &&
                item.contractStatus != '已过期'
              "
              >{{ item.contractStatus }}</span
            >
            <br />
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { ref } from "vue";
// import { Toast } from "vant";
import { useRoute } from "vue-router";
import { ConInformation } from "../../api";
import { Toast } from "vant";
export default {
  name: "ContractInformation",
  setup() {
    let route = useRoute();
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const token = ref(route.query.token);
    const userid = ref(route.query.userid);
    const total = ref("");
    const search = ref("");
    const screening = ref("");
    const showPicker = ref(false);
    const columns = [
      "全部",
      "框架合同",
      "单独合同（标准）",
      "单独合同（非标）",
      "有效期内",
      "即将过期",
      "已过期",
    ];
    const onSearch = (val) => {
      pageNum.value = 1;
      total.value = 0;
      search.value = val;
      list.value = [];
      onLoad();
    };
    const onConfirm = (value, index) => {
      screening.value = index;
      showPicker.value = false;
      pageNum.value = 1;
      console.log(search.value + screening.value + "," + index);
      list.value = [];
      onLoad();
    };
    const onLoad = () => {
      ConInformation({
        token: route.query[0],
        userid: route.query[1],
        roleid: route.query[3],
        findfactor: search.value,
        screening: screening.value,
        page: pageNum.value,
        size: 20,
      })
        .then((result) => {
          if (result.data == null) {
            total.value = 0;
            Toast(result.message);
          }
          if (refreshing.value) {
            pageNum.value = 1;
            list.value = [];
            refreshing.value = false;
          }
          if (result.data != null) {
            list.value.push(...result.data.list);
            if (result.data.total == 0) {
              total.value = result.data.list.length;
            } else {
              total.value = result.data.total;
            }
            loading.value = false;
            if (result.data.hasNextPage) {
              console.log(result.data.nextPage);
              pageNum.value = result.data.nextPage;
            } else {
              finished.value = true;
            }
          } else {
            finished.value = true;
          }
        })
        .catch((err) => {
          // Toast("网络错误");
          console.log(err);
          error.value = true;
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      search,
      onSearch,
      columns,
      showPicker,
      screening,
      onConfirm,
      list,
      onLoad,
      onRefresh,
      loading,
      refreshing,
      finished,
      token,
      userid,
      pageNum,
      total,
    };
  },
  methods: {
    returnContract(int) {
      switch (int) {
        case 1:
          return "框架合同";
        case 2:
          return "单独合同（标准）";
        case 3:
          return "单独合同（非标）";
        default:
          break;
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
        );
      }
    },
    // 返回
    onClickBack() {
      // 跳转上一级。
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        console.log(222);
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    jump(item) {
      console.log(item);
      let listData = item;
      listData.token = this.$route.query[0];
      listData.userid = this.$route.query[1];
      this.$router.push({
        path: "/ContractDetails",
        query: listData,
      });
    },
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
.van-search {
  background: #f3f3f3;
}
</style>
