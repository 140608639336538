<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="支出详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="main" style="padding-top: 46px"></div>
  <div v-show="this.$route.query.amountTax > 0">
    <span
      style="
        text-align: center;
        display: block;
        font-size: 14px;
        height: 20px;
        padding-top: 15px;
        color: rgb(151, 152, 154);
      "
      >支出金额:{{ this.$route.query.amountTax }}元</span
    >
  </div>
  <!-- 项目信息   -->
  <h5>项目信息</h5>
  <van-cell-group @click="jumpProject(projInfo.id)">
    <van-cell
      v-if="
        this.projInfo.relationFlag == 0 || this.projInfo.relationFlag == null
      "
      class="contractClass"
      :value="'【原单】' + this.projInfo.projectCode"
    />
    <van-cell
      v-if="this.projInfo.relationFlag == 1"
      class="contractClass"
      :value="'【子单】' + this.projInfo.projectCode"
    />
    <van-cell
      v-if="this.projInfo.relationFlag == 2"
      class="contractClass"
      :value="'【母单】' + this.projInfo.projectCode"
    />
    <van-cell
      :value="
        this.projInfo.projectName == null || this.projInfo.projectName == ''
          ? '暂无'
          : this.projInfo.projectName
      "
      v-if="this.projInfo.projectName != null"
    />
    <van-cell
      :value="this.projInfo.departmentName + this.projInfo.projectType"
      v-if="this.projInfo.projectType != null"
    />

    <van-cell
      :value="getOpenType(projInfo.executeStatus)"
      v-if="this.projInfo.executeStatus != null"
    />
    <van-cell
      :value="
        this.headUser.roleName == null || this.headUser.roleName == ''
          ? '品牌负责人:暂无'
          : '品牌负责人:' + this.headUser.truename
      "
      v-if="this.headUser.truename != null"
    />
    <van-cell
      :value="
        this.operationManagerUser == null || this.operationManagerUser == ''
          ? '项目经理:暂无'
          : '项目经理:' + this.operationManagerUser
      "
      v-if="this.operationManagerUser != null"
    />
    <van-cell
      :value="
        this.perationResponsibleUser == null ||
        this.perationResponsibleUser == ''
          ? '运营负责人:暂无'
          : '运营负责人:' + this.perationResponsibleUser
      "
      v-if="this.perationResponsibleUser != null"
    />
    <van-cell
      :value="
        this.desigrResponsibleUser == null || this.desigrResponsibleUser == ''
          ? '设计负责人:暂无'
          : '设计负责人:' + this.desigrResponsibleUser
      "
      v-if="this.desigrResponsibleUser != null"
    />
    <van-cell
      :value="'下单制作时间:' + formatDate(this.projInfo.receiptTime, 1)"
      v-if="projInfo.receiptTime != null"
    />
  </van-cell-group>
  <!-- 乙方信息 -->
  <h5>乙方信息</h5>
  <van-cell-group>
    <van-cell
      @click="jumpPartas(infoCompany.id)"
      class="contractClass"
      :value="
        this.infoCompany.name == null || this.infoCompany.name == ''
          ? '暂无'
          : '【乙方】' + this.infoCompany.name
      "
    />
    <van-cell
      @click="jumpPartas(infoCompany.id)"
      :value="
        this.infoCompany.egname == null || this.infoCompany.egname == ''
          ? '暂无'
          : this.infoCompany.egname
      "
    />
    <van-cell
      @click="jumpPartas(infoCompany.id)"
      :value="
        this.infoCompany.address == null || this.infoCompany.address == ''
          ? '暂无'
          : this.infoCompany.address
      "
    />
    <van-cell
      :value="this.infoCompany.contact + this.infoCompany.phone"
      @click="callPhone(this.infoCompany.phone)"
    />
  </van-cell-group>
  <!--支出信息 -->
  <h5>支出信息</h5>
  <van-cell-group inset>
    <van-cell
      title="支出金额"
      :value="this.infoCost.amountTax + '元'"
      v-if="infoCost.amountTax != null"
    />
    <van-cell
      title="税率"
      :value="this.infoCost.tax + '%'"
      v-if="infoCost.tax != null"
    />
    <van-cell
      title="不含税金额"
      :value="this.infoCost.amount + '元'"
      v-if="infoCost.amount != null"
    />
  </van-cell-group>
  <!--备注 -->
  <h5>备注</h5>
  <van-cell-group inset>
    <van-cell
      title="理由"
      :value="
        this.infoCost.costReasons == null || this.infoCost.costReasons == ''
          ? '暂无'
          : this.infoCost.costReasons
      "
      v-if="infoCost.costReasons != null"
    />
    <van-cell
      title="备注"
      :value="
        this.infoCost.remark == null || this.infoCost.remark == ''
          ? '暂无'
          : this.infoCost.remark
      "
      v-if="infoCost.remark != null"
    />
  </van-cell-group>
  <br />
</template>

<script>
import { Toast } from "vant";
import { ReportcenterCostdetail } from "../../api";
import { formatDate, roleName } from "../../js/xbTool";

export default {
  data: function () {
    return {
      contractLogList: [],
      // 是否有效
      headUser: {},
      infoContract: {},
      infoQuote: {},
      projInfo: {},
      invoiceList: [],
      costLogList: [],
      operationManagerUser: "",
      perationResponsibleUser: "",
      desigrResponsibleUser: "",
      //驳回
      bohuiShow: false,
      bohuiMessage: "",
      // 支出
      infoCost: {},
      // 乙方信息
      infoCompany: {},
    };
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    getDataDetails() {
      const that = this;
      ReportcenterCostdetail({
        costid: this.$route.query.costId,
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
      })
        .then((result) => {
          if (result.code == 1) {
            that.headUser = result.data.headUser;
            that.projInfo = result.data.projInfo;
            that.costLogList = result.data.costLogList;
            that.operationManagerUser = result.data.operationManagerUser;
            that.perationResponsibleUser = result.data.perationResponsibleUser;
            that.desigrResponsibleUser = result.data.desigrResponsibleUser;
            that.infoCost = result.data.infoCost;
            that.infoCompany = result.data.infoCompany;
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("网络错误");
        });
    },
    jumpProject(id) {
      let listData = [];
      listData.token = this.$route.query.z_token;
      listData.userid = this.$route.query.z_userid;
      listData.executeStatus = this.getOpenType(this.projInfo.executeStatus);
      listData.id = id;
      this.$router.push({
        path: "/ProjectDetails",
        query: listData,
      });
    },
    jumpPartas(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.z_token;
      listData.userid = this.$route.query.z_userid;
      listData.id = id;
      this.$router.push({
        path: "/PartyasDetails",
        query: listData,
      });
    },
    /* method */
    // 电话
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    // 时间转换
    formatDate(item, index) {
      return formatDate(item, index);
    },
    roleName(item) {
      return roleName(item);
    },
    getOpenType(type) {
      switch (type) {
        case 1:
          return "未开业";
        case 2:
          return "已开业";
        case 3:
          return "整改中";
        case 4:
          return "质保内";
        case 5:
          return "质保外";
        case 6:
          return "已完工";

        default:
          break;
      }
    },
  },
  // 获取详情
  mounted: function () {
    console.log(this.$route.query);
    this.getDataDetails();
  },
};
</script>

<style>
/* 基本 */
body::before {
  content: " ";
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(246, 246, 246);
  background-size: 100% auto;
}
.van-nav-bar .van-icon {
  color: black;
}
/* h5 */
h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(48, 48, 48);
  text-align: left;
  /* background-color: clear; */
}

.van-cell-group span {
  color: #323233;
  font-size: 14px;
  height: 33px;
}

.functionBtn {
  width: 40%;
  margin: 20px 5% 10px 5%;
}

.contractClass .van-cell__value span {
  color: rgb(229, 126, 5);
}
</style>
