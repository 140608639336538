<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="项目验收"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="ProjectInformation" style="padding-top: 46px">
    <van-search
      v-model="search"
      background="#f3f3f3"
      show-action
      placeholder="请输入项目单号(A10单号)或名称搜索"
      @search="onConfirm"
    >
      <template #action>
        <div @click="onConfirm" style="color: rgb(150, 151, 153)">搜索</div>
      </template>
    </van-search>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div v-show="total > 0">
          <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >项目合计:{{ total }}</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <div>
            <span style="color: #e57e05">项目单号：{{ item.projectCode }}</span>
            <br />
            <span v-if="item.biNo != null">A10单号：{{ item.biNo }}</span>
            <span v-if="item.biNo == null || item.biNo == ''"
              >A10单号：暂无</span
            >
            <br />
            <span>项目名称：{{ item.projectName }}</span>
            <br />
            <div>
              <span v-if="item.pmName != null"
                >品尚项目经理：{{ item.pmName }}</span
              >
              <span v-if="item.pmName == null">品尚项目经理：暂无</span>
            </div>
            <div>
              <span v-if="item.plantManagerId != null"
                >生产项目经理：{{ item.plantManagerId }}</span
              >
              <span v-if="item.plantManagerId == null">生产项目经理：暂无</span>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { useRoute } from "vue-router";
import { AcceptanceProjlist } from "../../api";
// import { Dialog } from "vant";
export default {
  name: "AcceptanceProList",
  setup() {
    let route = useRoute();
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const token = ref(route.query.token);
    const userid = ref(route.query.userid);
    const total = ref("");
    const search = ref("");
    const onSearch = (val) => {
      pageNum.value = 1;
      total.value = 0;
      console.log(val);
      search.value = val;
      list.value = [];
      onLoad();
    };
    const onConfirm = () => {
      pageNum.value = 1;
      list.value = [];
      total.value = 0;
      onLoad();
    };
    const onLoad = () => {
      AcceptanceProjlist({
        token: route.query[0],
        userid: route.query[1],
        keyword: search.value,
        page: pageNum.value,
        size: 10,
      })
        .then((result) => {
          if (result.data == null) {
            total.value = 0;
            Toast(result.message);
          }
          if (refreshing.value) {
            list.value = [];
            pageNum.value = 1;
            refreshing.value = false;
          }
          if (result.data != null) {
            list.value.push(...result.data.list);
            if (result.data.total == 0) {
              total.value = result.data.list.length;
            } else {
              total.value = result.data.total;
            }
            loading.value = false;
            if (result.data.hasNextPage) {
              console.log(result.data.nextPage);
              pageNum.value = result.data.nextPage;
            } else {
              finished.value = true;
            }
          } else {
            finished.value = true;
          }
        })
        .catch((err) => {
          Toast("网络错误");
          console.log(err);
          error.value = true;
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      search,
      onSearch,
      onConfirm,
      list,
      onLoad,
      onRefresh,
      loading,
      refreshing,
      finished,
      token,
      userid,
      pageNum,
      total,
    };
  },
  methods: {
    // 返回
    onClickBack() {
      // 跳转上一级。
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        console.log(222);
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    jump(item) {
      let listData = item;
      listData.token = this.$route.query[0];
      listData.userid = this.$route.query[1];
      this.$router.push({
        path: "/AcceptanceProDetails",
        query: listData,
      });
      return;
      // Dialog.confirm({
      //   title: "",
      //   confirmButtonText: "是",
      //   cancelButtonText: "否",
      //   message: "该项目是否需要执行项目验收操作",
      // })
      //   .then(() => {
      //     let listData = item;
      //     listData.token = this.$route.query[0];
      //     listData.userid = this.$route.query[1];
      //     this.$router.push({
      //       path: "/AcceptanceProDetails",
      //       query: listData,
      //     });
      //     // Acceptanceordercreate({
      //     //   token: that.$route.query.token,
      //     //   userid: that.$route.query.userid,
      //     //   projid: that.$route.query.id,
      //     //   type: index,
      //     // })
      //     //   .then((result) => {
      //     //     // console.log(result);
      //     //     if (result.code == 1) {
      //     //       console.log(result);
      //     //       let orderId = result.data;
      //     //       var que = {
      //     //         orderId: orderId,
      //     //         projId: that.$route.query.id,
      //     //         type: index,
      //     //         projCode: that.$route.query.projectCode,
      //     //       };
      //     //       // console.log(que);
      //     //       //成功后跳转
      //     //       if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      //     //         window.webkit.messageHandlers.toAcceptanceItem.postMessage({
      //     //           title: {
      //     //             orderId: que.orderId,
      //     //             projId: que.projId,
      //     //             type: que.type,
      //     //             projCode: que.projCode,
      //     //           }, //vue给iOS传值
      //     //         });
      //     //       } else {
      //     //         window.android.toAcceptanceItem(
      //     //           que.orderId,
      //     //           que.projId,
      //     //           que.type,
      //     //           que.projCode
      //     //         );
      //     //       }
      //     //       if (that.isR == true) {
      //     //         that.getDetails();
      //     //         that.isR = false;
      //     //       }
      //     //       //
      //     //       // this.getDetails();
      //     //     }
      //     //   })
      //     //   .catch((err) => {
      //     //     console.log(err);
      //     //     // this.finished = true;
      //     //     Toast("网络错误");
      //     //   });
      //   })
      //   .catch(() => {
      //     // on cancel
      //   });
    },
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
.van-search {
  background: #f3f3f3;
}
</style>
