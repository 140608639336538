<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    :title="this.$route.query[2] == 102104 ? '甲方信息' : '乙方信息'"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="PartyasInformation" style="padding-top: 46px">
    <van-search
      background="#f3f3f3"
      v-model="search"
      :placeholder="
        this.$route.query[2] == 102104
          ? '请输入甲方名称搜索'
          : '请输入乙方名称搜索'
      "
      @search="onSearch"
    >
    </van-search>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div v-show="total > 0">
          <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              color: rgb(150, 151, 153);
              display: block;
              font-size: 14px;
            "
            >{{ this.$route.query[2] == 102104 ? "甲方合计" : "乙方合计" }}:{{
              total
            }}</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <div>
            <span style="color: #e57e05">{{
              "【" + companyType(item.companyType) + "】" + item.name
            }}</span>
            <br />
            <span>{{ returnNull(item.egname) }}</span>
            <br />
            <span>{{ returnNull(item.address) }}</span>
            <br />
            <span v-if="item.contact == '暂无'">暂无</span>
            <span v-if="item.contact != '暂无'"
              >{{ item.contact }} {{ item.phone }}</span
            >
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { useRoute } from "vue-router";
import { FirstPartyInformation, SecondPartyInformation } from "../../api";
export default {
  name: "PartyasInformation",
  setup() {
    let route = useRoute();
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const token = ref(route.query.token);
    const userid = ref(route.query.userid);
    const total = ref("");
    const search = ref("");
    const screening = ref("");
    const onSearch = (val) => {
      pageNum.value = 1;
      total.value = 0;
      search.value = val;
      list.value = [];
      onLoad();
    };
    const onConfirm = (value, index) => {
      screening.value = index + 2;
      pageNum.value = 1;
      console.log(search.value + screening.value + "," + index);
      list.value = [];
      onLoad();
    };
    const onLoad = () => {
      if (route.query[2] == 102104) {
        FirstPartyInformation({
          token: route.query[0],
          userid: route.query[1],
          findfactor: search.value,
          page: pageNum.value,
          size: 20,
        })
          .then((result) => {
            if (result.data == null) {
              total.value = 0;
              Toast(result.message);
            }
            if (refreshing.value) {
              list.value = [];
              pageNum.value = 1;
              refreshing.value = false;
            }
            if (result.data != null) {
              list.value.push(...result.data.list);
              if (result.data.total == 0) {
                total.value = result.data.list.length;
              } else {
                total.value = result.data.total;
              }
              loading.value = false;
              if (result.data.hasNextPage) {
                console.log(result.data.nextPage);
                pageNum.value = result.data.nextPage;
              } else {
                finished.value = true;
              }
            } else {
              finished.value = true;
            }
          })
          .catch((err) => {
            Toast("网络错误");
            console.log(err);
            error.value = true;
            loading.value = false;
            finished.value = true;
          });
      } else {
        SecondPartyInformation({
          token: route.query[0],
          userid: route.query[1],
          findfactor: search.value,
          page: pageNum.value,
          size: 20,
        })
          .then((result) => {
            if (result.data == null) {
              total.value = 0;
              Toast(result.message);
            }
            if (refreshing.value) {
              list.value = [];
              refreshing.value = false;
            }
            if (result.data != null) {
              list.value.push(...result.data.list);
              if (result.data.total == 0) {
                total.value = result.data.list.length;
              } else {
                total.value = result.data.total;
              }
              loading.value = false;
              if (result.data.hasNextPage) {
                console.log(result.data.nextPage);
                pageNum.value = result.data.nextPage;
              } else {
                finished.value = true;
              }
            } else {
              finished.value = true;
            }
          })
          .catch((err) => {
            Toast("网络错误");
            console.log(err);
            error.value = true;
            loading.value = false;
            finished.value = true;
          });
      }
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      search,
      onSearch,
      screening,
      onConfirm,
      list,
      onLoad,
      onRefresh,
      loading,
      refreshing,
      finished,
      token,
      userid,
      pageNum,
      total,
    };
  },
  methods: {
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    companyType(int) {
      switch (int) {
        case 1:
          return "甲方";
        case 2:
          return "乙方";
        default:
          return "商场";
      }
    },
    // 返回
    onClickBack() {
      // 跳转上一级。
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    jump(item) {
      console.log(item + "ssas");
      let listData = item;
      listData.token = this.$route.query[0];
      listData.userid = this.$route.query[1];
      this.$router.push({
        path: "/PartyasDetails",
        query: listData,
      });
    },
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
.van-search {
  background: #f3f3f3;
}
</style>
