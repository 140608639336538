<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="考勤看板"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="AttendanceBoard" style="padding-top: 46px">
    <h5>打卡人数</h5>
    <van-cell-group inset>
      <van-cell
        title="今日打卡人数"
        :value="returnInt(resultData.todayClockNumber) + '人'"
      />
      <van-cell
        title="本月打卡人数"
        :value="returnInt(resultData.monthClockNumber) + '人'"
      />
    </van-cell-group>
    <h5>打卡时长</h5>
    <van-cell-group inset>
      <van-cell
        title="今日打卡时长"
        :value="minuteToDay(resultData.todayClockLength)"
      />
      <van-cell
        title="本月打卡时长"
        :value="minuteToDay(resultData.monthClockLength)"
      />
    </van-cell-group>
    <h5>打卡项目</h5>
    <van-cell-group inset>
      <van-cell
        title="今日打卡项目总数"
        :value="returnInt(resultData.todayClockProjNumber) + '个'"
      />
      <van-cell
        title="本月打卡项目总数"
        :value="returnInt(resultData.monthClockProjNumber) + '个'"
      />
    </van-cell-group>
  </div>
</template>

<script>
import { AttenceBoard } from "../../api";
import { Toast } from "vant";
export default {
  name: "AttendanceBoard",
  data() {
    return {
      resultData: "",
    };
  },
  methods: {
    // 返回
    onClickBack() {
      // 跳转上一级。
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        console.log(222);
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    returnInt(int) {
      if (int == null) {
        return 0;
      } else {
        return int;
      }
    },
    getDetails() {
      AttenceBoard({
        token: this.$route.query[0],
        userid: this.$route.query[1],
        roleid: this.$route.query[3],
      })
        .then((result) => {
          this.resultData = result.data;
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    minuteToDay(min) {
      let minTime = "";
      let d = Math.floor(min / (60 * 24));
      let h = Math.floor((min - d * 24 * 60) / 60);
      min = min - d * 24 * 60 - h * 60;
      if (d > 0) {
        minTime += d + "天";
      }
      if (h > 0) {
        minTime += h + "时";
      }
      if (min > 0) {
        minTime += min + "分";
      }
      return minTime == "" ? "0" : minTime;
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 10px;
}
</style>
