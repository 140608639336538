<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="项目信息"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="ProjectDetails" style="padding-top: 46px">
    <h5>基本信息</h5>
    <van-cell-group inset>
      <van-cell title="项目单号"
        ><span style="color: #e57e05; font-size: 14px"
          >【{{ returnType(projInfo.relationFlag) }}】{{
            projInfo.projectCode
          }}</span
        ></van-cell
      >
      <van-cell
        title="A10单号"
        :value="projInfo.biNo == null ? '暂无' : projInfo.biNo"
      />
      <van-cell title="项目名称" :value="projInfo.projectName" />
      <!-- <van-cell
        title="A10单号"
        :value="item.biNo == null ? '暂无' : item.biNo"
      /> -->

      <!-- <van-cell title="状态" :value="getOpenType(projInfo.executeStatus)" /> -->
      <van-cell title="品牌名称" :value="projInfo.departmentName" />
      <van-cell title="业务类型" :value="businessName" />
      <van-cell title="项目类型" :value="typeName" />
      <van-cell title="项目阶段" :value="processNode" />
      <van-cell
        title="子项目类型"
        :value="projInfo.projState"
        v-if="projInfo.projState != null"
      />
      <van-cell
        title="销售渠道"
        :value="projInfo.channel == null ? '暂无' : projInfo.channel"
      />

      <!-- <van-cell title="面积 (m²)" :value="projInfo.area" /> -->
      <van-cell title="是否有图纸" :value="returnCuSfdt(projInfo.cuSfdt)" />
      <van-cell title="是否收费" :value="returnCuSfdt(projInfo.isCharge)" />
      <van-cell
        title="重要信息"
        :value="returnNull(projInfo.projectImportant)"
      />
    </van-cell-group>
    <h5>合同信息</h5>
    <van-cell-group class="cell-group" inset v-if="infoContract == null">
      <van-cell
        ><span style="font-size: 14px"> 暂无 </span></van-cell
      ></van-cell-group
    >
    <van-cell-group
      class="cell-group"
      inset
      v-if="infoContract != null"
      @click="jumpContract(infoContract.id)"
    >
      <van-cell
        ><span style="color: #e57e05; font-size: 14px">
          {{ infoContract.contractName }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px">
          {{ returnContract(infoContract.type) }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span style="font-size: 14px; margin-left: 0px">
          {{ infoContract.partybName }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px">
          {{ infoContract.taxAmount }}元
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px">
          {{ infoContract.minDate }}至{{ infoContract.maxDate }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px">
          {{ contractStatus }}
        </span></van-cell
      >
    </van-cell-group>
    <h5 v-if="infoMarket">商场信息</h5>
    <van-cell-group inset v-if="infoMarket">
      <van-cell title="商场信息" :value="infoMarket" />
    </van-cell-group>
    <!-- <van-cell-group inset v-if="infoMarket == null">
      <van-cell title="商场联系人" :value="projInfo.marketName" />
      <van-cell
        title="联系电话"
        :value="projInfo.marketNum"
        @click="callPhone(projInfo.marketNum)"
      />
      <van-cell title="商场地址" :value="projInfo.marketAddress" />
    </van-cell-group>
    <van-cell-group
      class="cell-group"
      inset
      v-if="infoMarket != null"
      @click="jumpStore(infoMarket.id)"
    >
      <van-cell style="margin-left: 10px"
        ><span style="color: #e57e05; font-size: 14px; margin-left: 0px">
          {{ infoMarket.name }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span style="font-size: 14px; margin-left: 0px">
          {{ infoMarket.address }}
        </span></van-cell
      >
    </van-cell-group> -->

    <h5>投标信息</h5>
    <van-cell-group
      inset
      v-if="projInfoBil.winTheBid != '1' && projInfoBil.winTheBid != 0"
    >
      <van-cell
        ><span style="font-size: 14px"> 暂无 </span></van-cell
      ></van-cell-group
    >
    <van-cell-group
      inset
      v-if="projInfoBil.winTheBid == '1' || projInfoBil.winTheBid == 1"
    >
      <van-cell title="投标日期" :value="returnbidData(projInfoBil.bilDate)" />
      <van-cell title="面积" :value="projInfoBil.area" />
      <van-cell title="我方投标金额" :value="projInfoBil.ownAmount" />
      <van-cell title="每平米均价" :value="projInfoBil.ownAveragePrice" />
      <van-cell title="中标供应商名称" :value="projInfoBil.winSupplier" />
      <van-cell title="中标价格(未税)" :value="projInfoBil.winAmount" />
      <van-cell title="中标每平米均价" :value="projInfoBil.winAveragePrice" />
      <van-cell title="竞标对手名称" :value="projInfoBil.competitorName" />
      <van-cell title="备注" :value="projInfoBil.remark" />
    </van-cell-group>

    <h5>时间信息</h5>
    <van-cell-group inset>
      <van-cell
        title="下单时间"
        :value="formatDate(projInfo.createTime)"
        v-if="projInfo.createTime != null"
      />
      <van-cell
        title="下制作单"
        :value="formatDate(projInfo.receiptTime)"
        v-if="projInfo.receiptTime != null"
      />
      <van-cell
        title="交底时间"
        :value="formatDate(projInfo.deliveryTime)"
        v-if="projInfo.deliveryTime != null"
      />
      <van-cell
        title="框架验收"
        :value="formatDate(projInfo.checkTime)"
        v-if="projInfo.checkTime != null"
      />
      <van-cell
        title="出厂验收"
        :value="formatDate(projInfo.arriveCheckTime)"
        v-if="projInfo.arriveCheckTime != null"
      />
      <van-cell
        title="进场施工"
        :value="formatDate(projInfo.enteringSiteDate)"
        v-if="projInfo.enteringSiteDate != null"
      />
      <van-cell
        title="开业时间"
        :value="formatDate(projInfo.openingTime)"
        v-if="projInfo.openingTime != null"
      />
      <van-cell
        title="质保时间"
        :value="formatDate(projInfo.maintainDate)"
        v-if="projInfo.maintainDate != null"
      />
      <van-cell
        title="整改时间"
        :value="formatDate(projInfo.rectificationTime)"
        v-if="projInfo.rectificationTime != null"
      />
      <van-cell
        title="计划完工"
        :value="formatDate(projInfo.planFinishTime)"
        v-if="projInfo.planFinishTime != null"
      />
      <van-cell
        title="关闭时间"
        :value="formatDate(projInfo.closeTime)"
        v-if="projInfo.closeTime != null"
      />
    </van-cell-group>
    <h5>联系人信息</h5>
    <van-cell-group inset>
      <van-cell>
        <div class="div-left">
          <span style="margin-left: 0px">品牌负责人</span>
        </div>
        <div class="div-right">
          <span
            style="display: block; float: right; height: auto"
            v-for="item in headUser"
            :key="item"
            @click="callPhone(item.phone)"
          >
            {{ item.truename }} {{ item.phone }}
          </span>
        </div>
      </van-cell>
      <van-cell>
        <div class="div-left">
          <span style="margin-left: 0px">项目经理</span>
        </div>
        <div class="div-right">
          <span
            style="display: block; float: right"
            v-if="operationManagerUser == '暂无'"
            >暂无</span
          >
          <span
            style="display: block; float: right; height: auto"
            v-for="item in operationManagerUser"
            :key="item"
            @click="callPhone(item.phone)"
          >
            {{ item.truename }} {{ item.phone }}
          </span>
        </div>
      </van-cell>
      <van-cell>
        <div class="div-left">
          <span style="margin-left: 0px">设计负责人</span>
        </div>
        <div class="div-right">
          <span
            style="display: block; float: right"
            v-if="desigrResponsibleUser == '暂无'"
            >暂无</span
          >
          <span
            style="display: block; float: right; height: auto"
            v-for="item in desigrResponsibleUser"
            :key="item"
            @click="callPhone(item.phone)"
          >
            {{ item.truename }} {{ item.phone }}
          </span>
        </div>
      </van-cell>
      <van-cell>
        <div class="div-left">
          <span style="margin-left: 0px">运营负责人</span>
        </div>
        <div class="div-right">
          <span
            style="display: block; float: right"
            v-if="perationResponsibleUser == '暂无'"
            >暂无</span
          >
          <span
            style="display: block; float: right; height: auto"
            v-for="item in perationResponsibleUser"
            :key="item"
            @click="callPhone(item.phone)"
          >
            {{ item.truename }} {{ item.phone }}
          </span>
        </div>
      </van-cell>
      <van-cell>
        <div class="div-left">
          <span style="margin-left: 0px">制作工厂</span>
        </div>
        <div class="div-right">
          <span style="display: block; float: right">{{
            projInfo.factoryName
          }}</span>
        </div>
      </van-cell>
      <van-cell>
        <div class="div-left">
          <span style="margin-left: 0px">工厂负责人</span>
        </div>
        <div class="div-right">
          <!-- projInfo.factoryName +  -->
          <span
            style="display: block; float: right"
            v-if="returnNull(plantManagerUser.plantManagerId) == '暂无'"
            >暂无</span
          >
          <span
            style="display: block; float: right; height: auto"
            v-if="returnNull(plantManagerUser.plantManagerId) != '暂无'"
            @click="callPhone(plantManagerUser.plantManagerPhone)"
          >
            {{ returnNull(plantManagerUser.plantManagerId) }}
            {{ returnNull(plantManagerUser.plantManagerPhone) }}
          </span>
        </div>
      </van-cell>
    </van-cell-group>
    <h5>押金信息</h5>
    <van-cell-group inset v-if="projInfo.depositFlag == 0">
      <van-cell>暂无</van-cell>
    </van-cell-group>
    <van-cell-group inset v-if="projInfo.depositFlag == 1">
      <van-cell title="押金金额" :value="projInfo.deposit + '元'" />
      <van-cell title="是否退回" :value="getDeposit(projInfo.depositReturn)" />
      <van-cell
        title="备注"
        :value="
          projInfo.depositRemark == null || projInfo.depositRemark == ''
            ? '暂无'
            : projInfo.depositRemark
        "
      />
    </van-cell-group>
    <h5>报价信息</h5>
    <van-cell-group inset>
      <van-cell v-if="infoQuoteList.length == 0">暂无</van-cell>
      <van-cell
        v-for="item in infoQuoteList"
        :key="item"
        @click="jumpInvoice(item)"
      >
        <span
          style="color: #e57e05; font-size: 14px; margin-left: 0px"
          v-if="item.status == 3"
        >
          【{{ returnStatus(item.status) }}】</span
        >
        <span style="font-size: 14px; margin-left: 0px" v-if="item.status != 3">
          【{{ returnStatus(item.status) }}】</span
        >
        <span
          v-if="item.status == 3"
          style="color: #e57e05; font-size: 14px; margin-left: 0px"
          >{{ item.po }}</span
        >
        <span
          v-if="item.status != 3"
          style="font-size: 14px; margin-left: 0px"
          >{{ item.po }}</span
        >
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          税后金额:{{ item.amountTax }}元
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          税率:{{ item.tax }}%
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          不含税金额:{{ item.amount }}元
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          备注:{{ returnNull(item.remark) }}
        </span>
        <br />
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.createUser != null"
          >报价人:{{ item.createUser }}</span
        >
        <br />
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.createTime != null"
          >报价时间:{{ formatDate(item.createTime) }}</span
        >
        <br />
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.billingReason != null"
          >开票理由:{{ returnNull(item.billingReason) }}</span
        >
      </van-cell>
    </van-cell-group>
    <h5>备注</h5>
    <van-cell-group inset>
      <van-cell
        title="备注"
        :value="
          projInfo.projRemark == null || projInfo.projRemark == ''
            ? '暂无'
            : projInfo.projRemark
        "
      />
    </van-cell-group>
    <h5>销售合同</h5>
    <van-cell-group inset>
      <van-cell v-if="projContractList.length == 0">暂无</van-cell>
      <van-cell v-for="item in projContractList" :key="item">
        <span style="font-size: 14px; margin-left: 0px">
          合同名称:{{ returnNull(item.contrNm) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          归属品牌:{{ returnNull(item.xTradMrkNm) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          名称:{{ returnNull(item.custNm) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          税务登记号:{{ returnNull(item.taxChkNo) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          公司地址:{{ returnNull(item.addr) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          公司电话:{{ returnNull(item.phone) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          开户银行:{{ returnNull(item.accBn) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          银行账号:{{ returnNull(item.bnAccNo) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          合同金额:{{ returnNull(item.totAmt) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          币别:{{ returnNull(item.currency) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          税率:{{ returnNull(item.tax * 100) }}%
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          是否代开:{{ returnIsReplace(item.isReplace) }}
        </span>
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          单据日期:{{ returnNull(item.blDa) }}
        </span>
        <br />
      </van-cell>
    </van-cell-group>
    <br />
  </div>
</template>

<script>
import { ProjDetails } from "../../api";
import { Toast } from "vant";
export default {
  name: "ProjectDetails",
  data() {
    return {
      projInfo: "",
      infoContract: "",
      infoMarket: "",
      contractStatus: "",
      infoQuoteList: [],
      headUser: [],
      operationManagerUser: [],
      desigrResponsibleUser: [],
      perationResponsibleUser: [],
      plantManagerUser: [],
      typeName: "",
      businessName: "",
      processNode: "",
      projInfoBil: {},
      projContractList: [],
    };
  },
  methods: {
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    returnCuSfdt(string) {
      if (string == "1") {
        return "是";
      } else if (string == "2") {
        return "否";
      } else {
        return "暂无";
      }
    },
    returnIsReplace(string) {
      if (string == "1") {
        return "是";
      } else if (string == "0") {
        return "否";
      } else {
        return "暂无";
      }
    },
    returnbidData(string) {
      return string.slice(0, 10);
    },
    // returnIsCharge(string) {
    //   if (string == "1") {
    //     return "是";
    //   } else if (string == "2") {
    //     return "否";
    //   } else {
    //     return "暂无";
    //   }
    // },

    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "" || data === null) {
        return "暂无";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
        );
      }
    },
    returnStatus(status) {
      switch (status) {
        case 1:
          return "未开票";
        case 2:
          return "已申请";
        default:
          return "已开票";
      }
    },
    getOpenType(type) {
      switch (type) {
        case 1:
          return "未开业";
        case 2:
          return "已开业";
        case 3:
          return "整改中";
        case 4:
          return "质保内";
        case 5:
          return "质保外";
        case 6:
          return "已完工";

        default:
          break;
      }
    },
    getDeposit(int) {
      switch (int) {
        case 0:
          return "未退回";
        case 1:
          return "已退回";
        default:
          return "暂无押金";
      }
    },
    returnContract(int) {
      switch (int) {
        case 1:
          return "框架合同";
        case 2:
          return "单独合同（标准）";
        case 3:
          return "单独合同（非标）";
        default:
          break;
      }
    },
    returnType(int) {
      switch (int) {
        case 1:
          return "子单";
        case 2:
          return "母单";
        default:
          return "原单";
      }
    },
    jumpContract(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.contractId = id;
      this.$router.push({
        path: "/ContractDetails",
        query: listData,
      });
    },
    jumpInvoice(item) {
      console.log(item);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.id = item.id;
      listData.amountTax = item.amountTax;
      listData.invoice = "报价";
      if (item.isInvoice == 1) {
        this.$router.push({
          path: "/InvoiceDetails",
          query: listData,
        });
      } else {
        Toast("暂未开票");
      }
    },
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
    getDetails() {
      ProjDetails({
        token: this.$route.query.token,
        userid: this.$route.query.userid,
        projid: this.$route.query.id,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          // if (result.data.length > 0) {
          if (result.code == 1) {
            this.businessName = result.data.businessName;
            this.typeName = result.data.typeName;
            this.processNode = result.data.processNode;
            this.projInfo = result.data.projInfo;
            this.infoContract = result.data.infoContract;
            this.contractStatus = result.data.contractStatus;
            this.infoMarket = result.data.infoMarket;
            this.infoQuoteList = result.data.infoQuoteList;
            this.headUser.push(result.data.headUser);
            this.plantManagerUser = result.data.projInfo;
            this.operationManagerUser = result.data.operationManagerUser;
            this.desigrResponsibleUser = result.data.desigrResponsibleUser;
            this.perationResponsibleUser = result.data.perationResponsibleUser;
            this.projInfoBil = result.data.projInfoBil;
            this.projContractList = result.data.projContractList;
            this.$forceUpdate();
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    jumpStore(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.id = id;
      this.$router.push({
        path: "/StoreDetails",
        query: listData,
      });
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 10px;
}
.cell-group {
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
}
.div-left {
  float: left;
}
.div-right {
  width: 70%;
  float: right;
}
</style>
