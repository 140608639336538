import { createRouter, createWebHashHistory } from "vue-router";
import FlashPage from "./components/FlashPage.vue";
import AttendanceBoard from "./components/board/AttendanceBoard.vue";
import ProjectBoard from "./components/board/ProjectBoard.vue";
import FinancialBoard from "./components/board/FinancialBoard.vue";
import ProjectInformation from "./components/information/ProjectInformation.vue";
import ProjectDetails from "./components/information/ProjectDetails.vue";
import StoreInformation from "./components/information/StoreInformation.vue";
import StoreDetails from "./components/information/StoreDetails.vue";
import SupplierInformation from "./components/information/SupplierInformation.vue";
import SupplierDetails from "./components/information/SupplierDetails.vue";
import PartyasInformation from "./components/information/PartyasInformation.vue";
import PartyasDetails from "./components/information/PartyasDetails.vue";
import ContractInformation from "./components/information/ContractInformation.vue";
import ContractDetails from "./components/information/ContractDetails.vue";
import InvoiceInformation from "./components/information/InvoiceInformation.vue";
import InvoiceDetails from "./components/information/InvoiceDetails.vue";
import RemittanceStatistics from "./components/report/RemittanceStatistics.vue";
import RemittanceDetails from "./components/report/RemittanceDetails.vue";
import ExpenditureStatisticsList from "./components/report/ExpenditureStatisticsList.vue";
import ExpenditureStatisticsDetails from "./components/report/ExpenditureStatisticsDetails.vue";
import ContractReviewList from "../src/components/AuditCenter/ContractReviewList.vue";
import ContractReviewDetails from "../src/components/AuditCenter/ContractReviewDetails.vue";
import InvoiceReviewList from "../src/components/AuditCenter/InvoiceReviewList.vue";
import InvoiceReviewDetails from "../src/components/AuditCenter/InvoiceReviewDetails.vue";
import SupportAuditList from "../src/components/AuditCenter/SupportAuditList.vue";
import SupportAuditDetails from "../src/components/AuditCenter/SupportAuditDetails.vue";
import UseGuide from "../src/components/UseGuide.vue";
import AcceptanceProList from "../src/components/acceptance/AcceptanceProList.vue";
import AcceptanceProDetails from "../src/components/acceptance/AcceptanceProDetails.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: FlashPage,
    },
    {
      path: "/AttendanceBoard",
      component: AttendanceBoard,
    },
    {
      path: "/ProjectBoard",
      component: ProjectBoard,
    },
    {
      path: "/FinancialBoard",
      component: FinancialBoard,
    },
    {
      path: "/ProjectInformation",
      component: ProjectInformation,
    },
    {
      path: "/ProjectDetails",
      component: ProjectDetails,
    },
    {
      path: "/StoreInformation",
      component: StoreInformation,
    },
    {
      path: "/StoreDetails",
      component: StoreDetails,
    },
    {
      path: "/SupplierInformation",
      component: SupplierInformation,
    },
    {
      path: "/SupplierDetails",
      component: SupplierDetails,
    },
    {
      path: "/PartyasInformation",
      component: PartyasInformation,
    },
    {
      path: "/PartyasDetails",
      component: PartyasDetails,
    },
    {
      path: "/ContractInformation",
      component: ContractInformation,
    },
    {
      path: "/ContractDetails",
      component: ContractDetails,
    },
    {
      path: "/InvoiceInformation",
      component: InvoiceInformation,
    },
    {
      path: "/InvoiceDetails",
      component: InvoiceDetails,
    },
    {
      path: "/RemittanceStatistics",
      component: RemittanceStatistics,
    },
    {
      path: "/RemittanceDetails",
      component: RemittanceDetails,
    },
    {
      path: "/ContractReviewList",
      component: ContractReviewList,
    },
    {
      path: "/ContractReviewDetails",
      component: ContractReviewDetails,
    },
    {
      path: "/InvoiceReviewList",
      component: InvoiceReviewList,
    },
    {
      path: "/InvoiceReviewDetails",
      component: InvoiceReviewDetails,
    },
    {
      path: "/SupportAuditDetails",
      component: SupportAuditDetails,
    },
    {
      path: "/SupportAuditList",
      component: SupportAuditList,
    },
    {
      path: "/SupportAuditDetails",
      component: SupportAuditDetails,
    },
    {
      path: "/ExpenditureStatisticsList",
      component: ExpenditureStatisticsList,
    },
    {
      path: "/ExpenditureStatisticsDetails",
      component: ExpenditureStatisticsDetails,
    },
    {
      path: "/UseGuide",
      component: UseGuide,
    },
    {
      path: "/AcceptanceProDetails",
      component: AcceptanceProDetails,
    },
    {
      path: "/AcceptanceProList",
      component: AcceptanceProList,
    },
  ],
});

export default router;
