<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="项目看板"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="ProjectBoard" style="padding-top: 46px">
    <h5>项目总数</h5>
    <van-cell-group inset>
      <van-cell
        title="项目总数"
        :value="returnInt(resultData.acceptanceProject) + '个'"
      />
    </van-cell-group>
    <h5>新增项目</h5>
    <van-cell-group inset>
      <van-cell
        title="本月新增项目数"
        :value="returnInt(resultData.monthAddProject) + '个'"
      />
      <van-cell
        title="本周新增项目数"
        :value="returnInt(resultData.weekAddProject) + '个'"
      />
    </van-cell-group>
    <h5>已完工项目</h5>
    <van-cell-group inset>
      <van-cell
        title="已完工项目数"
        :value="returnInt(resultData.completedProject) + '个'"
      />
      <van-cell
        title="本月已完工项目数"
        :value="returnInt(resultData.monthCompletedProject) + '个'"
      />
      <van-cell
        title="本周已完工项目数"
        :value="returnInt(resultData.weekCompletedProject) + '个'"
      />
    </van-cell-group>
    <br />
    <span>未开业项目</span>
    <van-cell-group inset>
      <van-cell
        title="未开业项目数"
        :value="returnInt(resultData.unopenedProject) + '个'"
      />
    </van-cell-group>
    <h5>验收项目</h5>
    <van-cell-group inset>
      <van-cell
        title="框架验收项目数"
        :value="returnInt(resultData.acceptanceProject) + '个'"
      />
      <van-cell
        title="本月框架验收项目数"
        :value="returnInt(resultData.monthAcceptanceProject) + '个'"
      />
      <van-cell
        title="本周框架验收项目数"
        :value="returnInt(resultData.weekAcceptanceProject) + '个'"
      />
    </van-cell-group>
    <h5>出厂项目</h5>
    <van-cell-group inset>
      <van-cell
        title="出厂项目数"
        :value="returnInt(resultData.appearanceProject) + '个'"
      />
      <van-cell
        title="本月出厂项目数"
        :value="returnInt(resultData.monthAppearanceProject) + '个'"
      />
      <van-cell
        title="本周出厂项目数"
        :value="returnInt(resultData.weekAppearanceProject) + '个'"
      />
    </van-cell-group>
    <h5>已开业项目</h5>
    <van-cell-group inset>
      <van-cell
        title="已开业项目数"
        :value="returnInt(resultData.openingProject) + '个'"
      />
      <van-cell
        title="本月已开业项目数"
        :value="returnInt(resultData.monthOpeningProject) + '个'"
      />
      <van-cell
        title="本周已开业项目数"
        :value="returnInt(resultData.weekOpeningProject) + '个'"
      />
    </van-cell-group>
    <h5>整改中项目</h5>
    <van-cell-group inset>
      <van-cell
        title="整改中项目数"
        :value="returnInt(resultData.rectificationProject) + '个'"
      />
      <van-cell
        title="本月整改中项目数"
        :value="returnInt(resultData.monthRectificationProject) + '个'"
      />
      <van-cell
        title="本周整改中项目数"
        :value="returnInt(resultData.weekRectificationProject) + '个'"
      />
    </van-cell-group>
    <h5>质保中项目</h5>
    <van-cell-group inset>
      <van-cell
        title="质保中项目数"
        :value="returnInt(resultData.assuranceProject) + '个'"
      />
      <van-cell
        title="即将过保项目数"
        :value="returnInt(resultData.theBartenderProject) + '个'"
      />
      <van-cell
        title="已经过保项目数"
        :value="returnInt(resultData.bartenderProject) + '个'"
      />
    </van-cell-group>
    <br />
    <br />
  </div>
</template>

<script>
import { ProjBoard } from "../../api";
import { Toast } from "vant";
export default {
  name: "ProjectBoard",
  data() {
    return {
      resultData: "",
    };
  },
  methods: {
    returnInt(int) {
      if (int == null) {
        return 0;
      } else {
        return int;
      }
    },
    // 返回
    onClickBack() {
      // 跳转上一级。
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        console.log(222);
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    getDetails() {
      ProjBoard({
        token: this.$route.query[0],
        userid: this.$route.query[1],
        roleid: this.$route.query[3],
      })
        .then((result) => {
          this.resultData = result.data;
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    minuteToDay(min) {
      let minTime = "";
      let d = Math.floor(min / (60 * 24));
      let h = Math.floor((min - d * 24 * 60) / 60);
      min = min - d * 24 * 60 - h * 60;
      if (d > 0) {
        minTime += d + "天";
      }
      if (h > 0) {
        minTime += h + "时";
      }
      if (min > 0) {
        minTime += min + "分";
      }
      return minTime == "" ? "0" : minTime;
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 15px;
}
</style>
