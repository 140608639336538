<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="支出详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="main" style="padding-top: 46px"></div>
  <div v-show="this.$route.query.amountTax > 0">
    <span
      style="
        text-align: center;
        display: block;
        font-size: 14px;
        height: 20px;
        padding-top: 15px;
        color: rgb(151, 152, 154);
      "
      >支出金额:{{ this.$route.query.amountTax }}元</span
    >
  </div>
  <!-- 项目信息   -->
  <h5>项目信息</h5>
  <van-cell-group @click="jumpProject(projInfo.id)">
    <van-cell
      v-if="
        this.projInfo.relationFlag == 0 || this.projInfo.relationFlag == null
      "
      class="contractClass"
      :value="'【原单】' + this.projInfo.projectCode"
    />
    <van-cell
      v-if="this.projInfo.relationFlag == 1"
      class="contractClass"
      :value="'【子单】' + this.projInfo.projectCode"
    />
    <van-cell
      v-if="this.projInfo.relationFlag == 2"
      class="contractClass"
      :value="'【母单】' + this.projInfo.projectCode"
    />
    <van-cell
      :value="
        this.projInfo.projectName == null || this.projInfo.projectName == ''
          ? '暂无'
          : this.projInfo.projectName
      "
      v-if="projInfo.projectName != null"
    />
    <van-cell
      :value="this.projInfo.departmentName + this.projInfo.projectType"
    />

    <van-cell v-if="this.projInfo.executeStatus == 1" :value="'未开业'" />
    <van-cell v-if="this.projInfo.executeStatus == 2" :value="'已开业'" />
    <van-cell v-if="this.projInfo.executeStatus == 3" :value="'整改中'" />
    <van-cell v-if="this.projInfo.executeStatus == 4" :value="'质保内'" />
    <van-cell v-if="this.projInfo.executeStatus == 5" :value="'质保外'" />
    <van-cell v-if="this.projInfo.executeStatus == 5" :value="'已完工'" />
    <van-cell
      :value="
        this.headUser.truename == null || this.headUser.truename == ''
          ? '品牌负责人:暂无'
          : '品牌负责人:' + this.headUser.truename
      "
      v-if="headUser.truename != null"
    />
    <van-cell
      :value="
        this.operationManagerUser == null || this.operationManagerUser == ''
          ? '项目经理:暂无'
          : '项目经理:' + this.operationManagerUser
      "
      v-if="operationManagerUser != null"
    />
    <van-cell
      :value="
        this.perationResponsibleUser == null ||
        this.perationResponsibleUser == ''
          ? '运营负责人:暂无'
          : '运营负责人:' + this.perationResponsibleUser
      "
      v-if="perationResponsibleUser != null"
    />
    <van-cell
      :value="
        this.desigrResponsibleUser == null || this.desigrResponsibleUser == ''
          ? '设计负责人:暂无'
          : '设计负责人:' + this.desigrResponsibleUser
      "
      v-if="desigrResponsibleUser != null"
    />
    <van-cell
      :value="'下单制作时间:' + formatDate(this.projInfo.receiptTime, 1)"
      v-if="projInfo.receiptTime != null"
    />
  </van-cell-group>
  <!-- 乙方信息 -->
  <h5>乙方信息</h5>
  <van-cell-group>
    <van-cell
      @click="jumpPartas(infoCompany.id)"
      class="contractClass"
      :value="
        this.infoCompany.name == null || this.infoCompany.name == ''
          ? '暂无'
          : '【乙方】' + this.infoCompany.name
      "
    />
    <van-cell
      @click="jumpPartas(infoCompany.id)"
      :value="
        this.infoCompany.egname == null || this.infoCompany.egname == ''
          ? '暂无'
          : this.infoCompany.egname
      "
    />
    <van-cell
      @click="jumpPartas(infoCompany.id)"
      :value="
        this.infoCompany.address == null || this.infoCompany.address == ''
          ? '暂无'
          : this.infoCompany.address
      "
    />
    <van-cell
      :value="this.infoCompany.contact + this.infoCompany.phone"
      @click="callPhone(this.infoCompany.phone)"
    />
  </van-cell-group>
  <!--支出信息 -->
  <h5>支出信息</h5>
  <van-cell-group inset>
    <van-cell
      title="支出金额"
      :value="this.infoCost.amountTax + '元'"
      v-if="infoCost.amountTax != null"
    />
    <van-cell
      title="税率"
      :value="this.infoCost.tax + '%'"
      v-if="infoCost.tax != null"
    />
    <van-cell
      title="不含税金额"
      :value="this.infoCost.amount + '元'"
      v-if="infoCost.amount != null"
    />
  </van-cell-group>
  <!--备注 -->
  <h5>备注</h5>
  <van-cell-group inset>
    <van-cell
      title="理由"
      :value="
        this.infoCost.costReasons == null || this.infoCost.costReasons == ''
          ? '暂无'
          : this.infoCost.costReasons
      "
      v-if="infoCost.costReasons != null"
    />
    <van-cell
      title="备注"
      :value="
        this.infoCost.remark == null || this.infoCost.remark == ''
          ? '暂无'
          : this.infoCost.remark
      "
    />
  </van-cell-group>
  <!-- 按钮 -->
  <div>
    <van-button
      class="functionBtn"
      color="rgb(229, 126, 5)"
      round
      @click="adoptAction"
      >通过</van-button
    >
    <van-button
      class="functionBtn"
      color="rgb(229, 126, 5)"
      plain
      round
      @click="rejectAction"
      >驳回</van-button
    >
    <div v-if="this.costLogList">
      <h5>日志</h5>
      <van-cell-group inset>
        <van-cell v-for="item in costLogList" :key="item">
          <span
            style="font-size: 14px"
            v-if="item.status == 0 || item.status == 1 || item.status == 2"
            >{{
              formatDate(item.createTime, 2) +
              "    " +
              roleName(item.roleId) +
              "    " +
              item.createName +
              "    " +
              item.approveRemark
            }}</span
          >
          <span v-if="item.status == 3" style="color: red; font-size: 14px">{{
            formatDate(item.createTime, 2) +
            "    " +
            roleName(item.roleId) +
            "    " +
            item.approveUserName +
            "    审核拒绝:" +
            item.approveRemark
          }}</span>
          <span v-if="item.status == 4" style="color: green; font-size: 14px">{{
            formatDate(item.createTime, 2) +
            "    " +
            roleName(item.roleId) +
            "    " +
            item.approveUserName +
            "   审核通过"
          }}</span>
        </van-cell>
      </van-cell-group>
      <br />
    </div>
  </div>
  <!-- 驳回 -->
  <van-popup
    v-model:show="bohuiShow"
    round
    :style="{ width: '90%', height: '40%' }"
  >
    <span
      style="
        text-align: center;
        display: block;
        line-height: 33px;
        text-align: center;
        display: block;
        font-size: 14px;
        height: 33px;
      "
      >请填写驳回理由</span
    >
    <van-field
      v-model="bohuiMessage"
      rows="3"
      autosize
      type="textarea"
      maxlength="50"
      placeholder="请输入"
      show-word-limit
    />

    <van-button
      style="
        display: block;
        margin: 0 auto;
        width: 65%;
        height: 44px;
        top: 31px;
      "
      type="primary"
      size="large"
      round
      :disabled="disable"
      @click="bohuiAction"
      >确认驳回</van-button
    >
  </van-popup>
</template>

<script>
import { Toast } from "vant";
import { ReportcenterCostdetail, TodoAuditprocess } from "../../api";
import { formatDate, roleName } from "../../js/xbTool";
import { Dialog } from "vant";

export default {
  data: function () {
    return {
      contractLogList: [],
      // 是否有效
      headUser: {},
      infoContract: {},
      infoQuote: {},
      projInfo: {},
      invoiceList: [],
      costLogList: [],
      operationManagerUser: "",
      perationResponsibleUser: "",
      desigrResponsibleUser: "",
      //驳回
      bohuiShow: false,
      bohuiMessage: "",
      // 支出
      infoCost: {},
      // 乙方信息
      infoCompany: {},
    };
  },
  methods: {
    getOpenType(type) {
      switch (type) {
        case 1:
          return "未开业";
        case 2:
          return "已开业";
        case 3:
          return "整改中";
        case 4:
          return "质保内";
        case 5:
          return "质保外";
        case 6:
          return "已完工";

        default:
          break;
      }
    },
    jumpProject(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.z_token;
      listData.userid = this.$route.query.z_userid;
      listData.executeStatus = this.getOpenType(this.projInfo.executeStatus);
      listData.id = id;
      this.$router.push({
        path: "/ProjectDetails",
        query: listData,
      });
    },
    jumpPartas(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.z_token;
      listData.userid = this.$route.query.z_userid;
      listData.id = id;
      this.$router.push({
        path: "/PartyasDetails",
        query: listData,
      });
    },
    onClickBack() {
      this.$router.go(-1);
    },
    getDataDetails() {
      const that = this;
      ReportcenterCostdetail({
        costid: this.$route.query.costId,
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
      })
        .then((result) => {
          if (result.code == 1) {
            that.headUser = result.data.headUser;
            that.projInfo = result.data.projInfo;
            that.costLogList = result.data.costLogList;
            that.operationManagerUser = result.data.operationManagerUser;
            that.perationResponsibleUser = result.data.perationResponsibleUser;
            that.desigrResponsibleUser = result.data.desigrResponsibleUser;
            that.infoCost = result.data.infoCost;
            that.infoCompany = result.data.infoCompany;
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("网络错误");
        });
    },
    // 通过
    adoptAction() {
      Dialog.confirm({
        title: "",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: "是否通过该支出",
      })
        .then(() => {
          Toast.loading({
            duration: 0,
            message: "通过中...",
            forbidClick: true,
          });
          TodoAuditprocess({
            approveremark: "审核通过",
            approvestatus: 1,
            auditid: this.$route.query.costId,
            audittype: 3,
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
          })
            .then((result) => {
              if (result.code == 1) {
                Toast(result.data);
                this.onClickBack();
              } else {
                Toast(result.data);
              }
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 驳回
    rejectAction() {
      this.bohuiShow = true;
    },
    // 确认驳回
    bohuiAction() {
      if (this.bohuiMessage == "") {
        Toast("请输入驳回理由");
        return;
      }
      Dialog.confirm({
        title: "",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: "是否驳回该支出",
      })
        .then(() => {
          Toast.loading({
            duration: 0,
            message: "驳回中...",
            forbidClick: true,
          });
          TodoAuditprocess({
            approveremark: this.bohuiMessage,
            approvestatus: 2,
            auditid: this.$route.query.costId,
            audittype: 3,
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
          })
            .then((result) => {
              if (result.code == 1) {
                Toast(result.data);
                this.onClickBack();
              } else {
                Toast(result.data);
              }
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
    },

    /* method */
    // 电话
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    // 时间转换
    formatDate(item, index) {
      return formatDate(item, index);
    },
    roleName(item) {
      return roleName(item);
    },
  },
  // 获取详情
  mounted: function () {
    console.log(this.$route.query);
    this.getDataDetails();
  },
};
</script>

<style>
/* 基本 */
body::before {
  content: " ";
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(246, 246, 246);
  background-size: 100% auto;
}
.van-nav-bar .van-icon {
  color: black;
}
/* h5 */
h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(48, 48, 48);
  text-align: left;
  /* background-color: clear; */
}

.van-cell-group span {
  color: #323233;
  font-size: 14px;
  height: 33px;
}

.functionBtn {
  width: 40%;
  margin: 20px 5% 10px 5%;
}

.contractClass .van-cell__value span {
  color: rgb(229, 126, 5);
}
</style>
