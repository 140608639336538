<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="发票详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="main" style="padding-top: 46px"></div>
  <!-- 基本信息 -->
  <h5>合同信息</h5>
  <van-cell-group @click="jumpContract(infoContract.id)">
    <van-cell
      class="contractClass"
      :value="
        this.infoContract.contractName == null ||
        this.infoContract.contractName == ''
          ? '暂无'
          : this.infoContract.contractName
      "
      v-if="infoContract.contractName != null"
    />
    <van-cell v-if="this.infoContract.status == 1" value="框架合同"></van-cell>
    <van-cell
      v-if="this.infoContract.status == 2"
      value="单独合同(标准)"
    ></van-cell>
    <van-cell
      v-if="this.infoContract.status == 3"
      value="单独合同(非标)"
    ></van-cell>
    <van-cell v-if="this.infoContract.status == 4" value="开票"></van-cell>
    <van-cell v-if="this.infoContract.status == 5" value="支出"></van-cell>
    <van-cell
      :value="
        this.infoContract.partyaName == null ||
        this.infoContract.partyaName == ''
          ? '暂无'
          : this.infoContract.partyaName
      "
      v-if="infoContract.partyaName != null"
    />
    <van-cell
      :value="
        this.infoContract.taxAmount == null || this.infoContract.taxAmount == ''
          ? '暂无'
          : this.infoContract.taxAmount + '元'
      "
      v-if="infoContract.taxAmount != null"
    />
    <van-cell
      :value="this.infoContract.minDate + '至' + this.infoContract.maxDate"
      v-if="infoContract.minDate != null"
    />
    <van-cell
      :value="
        this.contractStatus == null || this.contractStatus == ''
          ? '暂无'
          : this.contractStatus
      "
      v-if="contractStatus != null"
    />
  </van-cell-group>
  <!-- 项目信息   -->
  <h5>项目信息</h5>
  <van-cell-group @click="jumpProject(projInfo.id)">
    <van-cell
      v-if="this.projInfo.relationFlag == 0"
      class="contractClass"
      :value="'【原单】' + this.projInfo.projectCode"
    />
    <van-cell
      v-if="this.projInfo.relationFlag == 1"
      class="contractClass"
      :value="'【子单】' + this.projInfo.projectCode"
    />
    <van-cell
      v-if="this.projInfo.relationFlag == 2"
      class="contractClass"
      :value="'【母单】' + this.projInfo.projectCode"
    />
    <van-cell
      :value="
        this.projInfo.projectName == null || this.projInfo.projectName == ''
          ? '暂无'
          : this.projInfo.projectName
      "
      v-if="projInfo.projectName != null"
    />
    <van-cell
      :value="this.projInfo.departmentName + this.projInfo.projectType"
      v-if="projInfo.departmentName != null"
    />

    <van-cell v-if="this.projInfo.executeStatus == 1" :value="'未开业'" />
    <van-cell v-if="this.projInfo.executeStatus == 2" :value="'已开业'" />
    <van-cell v-if="this.projInfo.executeStatus == 3" :value="'整改中'" />
    <van-cell v-if="this.projInfo.executeStatus == 4" :value="'质保内'" />
    <van-cell v-if="this.projInfo.executeStatus == 5" :value="'质保外'" />
    <van-cell v-if="this.projInfo.executeStatus == 5" :value="'已完工'" />
    <van-cell
      :value="
        this.headUser.truename == null || this.headUser.truename == ''
          ? '品牌负责人:暂无'
          : '品牌负责人:' + this.headUser.truename
      "
      v-if="headUser.truename != null"
    />
    <van-cell
      :value="
        this.operationManagerUser == null || this.operationManagerUser == ''
          ? '项目经理:暂无'
          : '项目经理:' + this.operationManagerUser
      "
      v-if="operationManagerUser != null"
    />
    <van-cell
      :value="
        this.perationResponsibleUser == null ||
        this.perationResponsibleUser == ''
          ? '运营负责人:暂无'
          : '运营负责人:' + this.perationResponsibleUser
      "
      v-if="perationResponsibleUser != null"
    />
    <van-cell
      :value="
        this.desigrResponsibleUser == null || this.desigrResponsibleUser == ''
          ? '设计负责人:暂无'
          : '设计负责人:' + this.desigrResponsibleUser
      "
      v-if="desigrResponsibleUser != null"
    />
    <van-cell
      :value="'下单制作时间:' + formatDate(this.projInfo.receiptTime, 1)"
      v-if="projInfo.receiptTime != null"
    />
  </van-cell-group>
  <!--报价信息 -->
  <h5>报价信息</h5>
  <van-cell-group inset>
    <van-cell
      v-if="this.infoQuote.isInvoice == 0"
      class="contractClass"
      :value="
        this.infoQuote.po == null || this.infoQuote.po == ''
          ? '【未开票】暂无'
          : '【未开票】' + this.infoQuote.po
      "
    />
    <van-cell
      v-if="this.infoQuote.isInvoice == 1"
      class="contractClass"
      :value="
        this.infoQuote.po == null || this.infoQuote.po == ''
          ? '【已开票】暂无'
          : '【已开票】' + this.infoQuote.po
      "
    />
    <van-cell
      :value="
        this.infoQuote.amount == null || this.infoQuote.amount == ''
          ? '税后金额:暂无'
          : '税后金额:' + this.infoQuote.amountTax
      "
      v-if="infoContract.contractName != null"
    />
    <van-cell
      :value="
        this.infoQuote.tax == null || this.infoQuote.tax == ''
          ? '税率:暂无'
          : '税率:' + this.infoQuote.tax + '%'
      "
      v-if="infoContract.contractName != null"
    />
    <van-cell
      :value="
        this.infoQuote.amountTax == null || this.infoQuote.amountTax == ''
          ? '税前金额:暂无'
          : '税前金额:' + this.infoQuote.amount
      "
      v-if="infoContract.contractName != null"
    />
    <van-cell
      :value="
        this.infoQuote.remark == null || this.infoQuote.remark == ''
          ? '备注:暂无'
          : '备注:' + this.infoQuote.remark
      "
      v-if="infoContract.contractName != null"
    />
  </van-cell-group>
  <!--发票信息 list-->
  <h5>发票信息</h5>
  <van-cell-group inset>
    <van-cell v-for="item in invoiceList" :key="item">
      <span style="color: rgb(229, 126, 5)">{{
        "发票号:" + item.invoiceNum
      }}</span>
      <br />
      <span>{{ "发票金额:" + item.invoiceTaxAmount + "元" }}</span>
      <br />
      <span>{{ "税率:" + item.tax + "%" }}</span>
      <br />
      <span>{{ "税前金额:" + item.invoiceAmount + "元" }}</span>
      <br />
      <span>{{ "开票时间:" + item.invoiceDate }}</span>
      <br />
      <span>{{ "开票理由:" + item.remark }}</span>
      <br />
    </van-cell>
  </van-cell-group>
  <!-- 按钮 -->
  <div>
    <van-button
      class="functionBtn"
      color="rgb(229, 126, 5)"
      round
      @click="adoptAction"
      >通过</van-button
    >
    <van-button
      class="functionBtn"
      color="rgb(229, 126, 5)"
      plain
      round
      @click="rejectAction"
      >驳回</van-button
    >
    <div v-if="this.invoiceLogList">
      <h5>日志</h5>
      <van-cell-group inset>
        <van-cell v-for="item in invoiceLogList" :key="item">
          <span
            style="font-size: 14px"
            v-if="item.status == 0 || item.status == 1 || item.status == 2"
            >{{
              formatDate(item.createTime, 2) +
              "    " +
              roleName(item.roleId) +
              "    " +
              item.createName +
              "    " +
              item.approveRemark
            }}</span
          >
          <span v-if="item.status == 3" style="color: red; font-size: 14px">{{
            formatDate(item.createTime, 2) +
            "    " +
            roleName(item.roleId) +
            "    " +
            item.approveUserName +
            "    审核拒绝:" +
            item.approveRemark
          }}</span>
          <span v-if="item.status == 4" style="color: green; font-size: 14px">{{
            formatDate(item.createTime, 2) +
            "    " +
            roleName(item.roleId) +
            "    " +
            item.approveUserName +
            "   审核通过"
          }}</span>
        </van-cell>
      </van-cell-group>
      <br />
    </div>

    <!-- 驳回 -->
    <van-popup
      v-model:show="bohuiShow"
      round
      :style="{ width: '90%', height: '40%' }"
    >
      <span
        style="
          text-align: center;
          display: block;
          line-height: 33px;
          text-align: center;
          display: block;
          font-size: 14px;
          height: 33px;
        "
        >请填写驳回理由</span
      >
      <van-field
        v-model="bohuiMessage"
        rows="3"
        autosize
        type="textarea"
        maxlength="50"
        placeholder="请输入"
        show-word-limit
      />

      <van-button
        style="
          display: block;
          margin: 0 auto;
          width: 65%;
          height: 44px;
          top: 31px;
        "
        type="primary"
        size="large"
        round
        :disabled="disable"
        @click="bohuiAction"
        >确认驳回</van-button
      >
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { DatacenterInvoicedetails, TodoAuditprocess } from "../../api";
import { formatDate, roleName } from "../../js/xbTool";
import { Dialog } from "vant";

export default {
  data: function () {
    return {
      contractLogList: [],
      // 是否有效
      headUser: {},
      infoContract: {},
      infoQuote: {},
      projInfo: {},
      invoiceList: [],
      invoiceLogList: [],
      operationManagerUser: "",
      perationResponsibleUser: "",
      desigrResponsibleUser: "",
      //驳回
      bohuiShow: false,
      bohuiMessage: "",
      // 即将过期
      contractStatus: "",
    };
  },
  methods: {
    jumpContract(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.z_token;
      listData.userid = this.$route.query.z_userid;
      listData.contractId = id;
      this.$router.push({
        path: "/ContractDetails",
        query: listData,
      });
    },
    jumpProject(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.z_token;
      listData.userid = this.$route.query.z_userid;
      listData.executeStatus = this.getOpenType(this.projInfo.executeStatus);
      listData.id = id;
      this.$router.push({
        path: "/ProjectDetails",
        query: listData,
      });
    },
    onClickBack() {
      this.$router.go(-1);
    },
    getDataDetails() {
      const that = this;
      DatacenterInvoicedetails({
        invoiceid: this.$route.query.id,
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
      })
        .then((result) => {
          if (result.code == 1) {
            that.headUser = result.data.headUser;
            that.infoContract = result.data.infoContract;
            that.infoQuote = result.data.infoQuote;
            that.projInfo = result.data.projInfo;
            that.invoiceList = result.data.invoiceList;
            that.invoiceLogList = result.data.invoiceLogList;
            that.operationManagerUser = result.data.operationManagerUser;
            that.perationResponsibleUser = result.data.perationResponsibleUser;
            that.contractStatus = result.data.contractStatus;
            that.desigrResponsibleUser = result.data.desigrResponsibleUser;
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("网络错误");
        });
    },
    // 通过
    adoptAction() {
      Dialog.confirm({
        title: "",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: "是否通过该发票",
      })
        .then(() => {
          TodoAuditprocess({
            approveremark: "审核通过",
            approvestatus: 1,
            auditid: this.$route.query.id,
            audittype: 2,
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
          })
            .then((result) => {
              if (result.code == 1) {
                Toast(result.data);
                this.onClickBack();
              } else {
                Toast(result.data);
              }
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 驳回
    rejectAction() {
      this.bohuiShow = true;
    },
    // 确认驳回
    bohuiAction() {
      if (this.bohuiMessage == "") {
        Toast("请输入驳回理由");
        return;
      }
      Dialog.confirm({
        title: "",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: "是否驳回该发票",
      })
        .then(() => {
          TodoAuditprocess({
            approveremark: this.bohuiMessage,
            approvestatus: 2,
            auditid: this.$route.query.id,
            audittype: 2,
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
          })
            .then((result) => {
              if (result.code == 1) {
                Toast(result.data);
                this.onClickBack();
              } else {
                Toast(result.data);
              }
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    /* method */
    // 电话
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    // 时间转换
    formatDate(item, index) {
      return formatDate(item, index);
    },
    roleName(item) {
      return roleName(item);
    },
  },
  // 获取详情
  mounted: function () {
    console.log(this.$route.query);
    this.getDataDetails();
  },
};
</script>

<style>
/* 基本 */
body::before {
  content: " ";
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(246, 246, 246);
  background-size: 100% auto;
}
.van-nav-bar .van-icon {
  color: black;
}
/* h5 */
h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(48, 48, 48);
  text-align: left;
  /* background-color: clear; */
}

.van-cell-group span {
  color: #323233;
  font-size: 14px;
  height: 33px;
}
.contractClass .van-cell__value span {
  color: rgb(229, 126, 5);
}
.functionBtn {
  width: 40%;
  margin: 20px 5% 10px 5%;
}
</style>
