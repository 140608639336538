<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="合同详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="main" style="padding-top: 46px"></div>
  <!-- 基本信息 -->
  <h5>基本信息</h5>
  <van-cell-group inset>
    <van-cell
      class="contractClass"
      title="合同名称"
      :value="
        $route.query.contractName == null || $route.query.contractName == ''
          ? '暂无'
          : $route.query.contractName
      "
    />
    <van-cell
      title="合同类型"
      :value="returnContract($route.query.type)"
    ></van-cell>
    <van-cell
      title="是否有效"
      :value="
        this.contractStatus == null || this.contractStatus == ''
          ? '暂无'
          : this.contractStatus
      "
    />
    <van-cell
      title="合同金额"
      :value="this.infoContract.taxAmount + '元'"
      v-if="infoContract.taxAmount != null"
    />
    <van-cell
      title="税率"
      :value="this.infoContract.taxRate + '%'"
      v-if="infoContract.taxRate != null"
    />
    <van-cell
      title="不含税金额"
      :value="this.infoContract.amount + '元'"
      v-if="infoContract.amount != null"
    />
    <van-cell
      title="回款条件"
      :value="
        this.infoContract.backCondition == null ||
        this.infoContract.backCondition == ''
          ? '暂无'
          : this.infoContract.backCondition
      "
      v-if="infoContract.backCondition != null"
    />
    <van-cell
      title="签订日期"
      :value="
        this.infoContract.signDate == null || this.infoContract.signDate == ''
          ? '暂无'
          : this.infoContract.signDate
      "
      v-if="infoContract.signDate != null"
    />
    <van-cell
      title="有效期"
      :value="this.infoContract.minDate + '至' + this.infoContract.maxDate"
      v-if="infoContract.minDate != null"
    />
    <van-cell
      title="存档归属地"
      :value="
        this.infoContract.filingTerritory == null ||
        this.infoContract.filingTerritory == ''
          ? '暂无'
          : this.infoContract.filingTerritory
      "
      v-if="infoContract.filingTerritory != null"
    />
    <van-cell
      title="登记人"
      :value="
        this.infoContract.createUser == null ||
        this.infoContract.createUser == ''
          ? '暂无'
          : this.infoContract.createUser
      "
      v-if="infoContract.createUser != null"
    />
    <van-cell
      title="登记日期"
      :value="formatDate(this.infoContract.createTime, 1)"
      v-if="infoContract.createTime != null"
    />
  </van-cell-group>
  <!-- 甲方信息 -->
  <h5>甲方信息</h5>
  <van-cell-group inset>
    <van-cell
      title="甲方名称"
      :value="
        this.partyA.name == null || this.partyA.name == ''
          ? '暂无'
          : this.partyA.name
      "
      v-if="partyA.name != null"
    />
    <van-cell
      title="公司税号"
      :value="
        this.partyA.taxNum == null || this.partyA.taxNum == ''
          ? '暂无'
          : this.partyA.taxNum
      "
      v-if="partyA.taxNum != null"
    />
    <van-cell
      title="开户银行"
      :value="
        this.partyA.depositBank == null || this.partyA.depositBank == ''
          ? '暂无'
          : this.partyA.depositBank
      "
      v-if="partyA.depositBank != null"
    />
    <van-cell
      title="银行账号"
      :value="
        this.partyA.accountNumber == null || this.partyA.accountNumber == ''
          ? '暂无'
          : this.partyA.accountNumber
      "
      v-if="partyA.accountNumber != null"
    />
    <van-cell
      title="甲方地址"
      :value="
        this.partyA.address == null || this.partyA.address == ''
          ? '暂无'
          : this.partyA.address
      "
      v-if="partyA.address != null"
    />
    <van-cell
      title="甲方联系人"
      :value="
        this.partyA.contact == null || this.partyA.contact == ''
          ? '暂无'
          : this.partyA.contact
      "
      v-if="partyA.contact != null"
    />
    <van-cell
      @click="callPhone(this.partyA.phone)"
      title="联系人电话"
      :value="this.partyA.phone"
      v-if="partyA.phone != null"
    />
  </van-cell-group>
  <!--支出信息 -->
  <h5>乙方信息</h5>
  <van-cell-group inset>
    <van-cell
      title="乙方名称"
      :value="
        this.partyB.name == null || this.partyB.name == ''
          ? '暂无'
          : this.partyB.name
      "
      v-if="partyB.name != null"
    />
    <van-cell
      title="公司税号"
      :value="
        this.partyB.taxNum == null || this.partyB.taxNum == ''
          ? '暂无'
          : this.partyB.taxNum
      "
      v-if="partyB.taxNum != null"
    />
    <van-cell
      title="开户银行"
      :value="
        this.partyB.depositBank == null || this.partyB.depositBank == ''
          ? '暂无'
          : this.partyB.depositBank
      "
      v-if="partyB.depositBank != null"
    />
    <van-cell
      title="银行账号"
      :value="
        this.partyB.accountNumber == null || this.partyB.accountNumber == ''
          ? '暂无'
          : this.partyB.accountNumber
      "
      v-if="partyB.accountNumber != null"
    />
    <van-cell
      title="乙方地址"
      :value="
        this.partyB.address == null || this.partyB.address == ''
          ? '暂无'
          : this.partyB.address
      "
      v-if="partyB.address != null"
    />
    <van-cell
      title="乙方联系人"
      :value="
        this.partyB.contact == null || this.partyB.contact == ''
          ? '暂无'
          : this.partyB.contact
      "
      v-if="partyB.contact != null"
    />
    <van-cell
      @click="callPhone(this.partyB.phone)"
      title="联系人电话"
      :value="this.partyB.phone"
      v-if="partyB.phone != null"
    />
  </van-cell-group>
  <!--备注 -->
  <h5>备注</h5>
  <van-cell-group inset>
    <van-cell
      title="备注"
      :value="
        this.infoContract.remark == null || this.infoContract.remark == ''
          ? '暂无'
          : this.infoContract.remark
      "
    />
  </van-cell-group>
  <!-- 按钮 -->
  <div>
    <van-button
      class="functionBtn"
      color="rgb(229, 126, 5)"
      round
      @click="adoptAction"
      >通过</van-button
    >
    <van-button
      class="functionBtn"
      color="rgb(229, 126, 5)"
      plain
      round
      @click="rejectAction"
      >驳回</van-button
    >
    <div v-if="this.contractLogList">
      <h5>日志</h5>
      <van-cell-group inset>
        <van-cell v-for="item in contractLogList" :key="item">
          <span
            style="font-size: 14px"
            v-if="item.status == 0 || item.status == 1 || item.status == 2"
            >{{
              formatDate(item.createTime, 2) +
              "    " +
              roleName(item.roleId) +
              "    " +
              item.createName +
              "    " +
              item.approveRemark
            }}</span
          >
          <span v-if="item.status == 3" style="color: red; font-size: 14px">{{
            formatDate(item.createTime, 2) +
            "    " +
            roleName(item.roleId) +
            "    " +
            item.approveUserName +
            "    审核拒绝:" +
            item.approveRemark
          }}</span>
          <span v-if="item.status == 4" style="color: green; font-size: 14px">{{
            formatDate(item.createTime, 2) +
            "    " +
            roleName(item.roleId) +
            "    " +
            item.approveUserName +
            "   审核通过"
          }}</span>
        </van-cell>
      </van-cell-group>
      <br />
    </div>

    <!-- 驳回 -->
    <van-popup
      v-model:show="bohuiShow"
      round
      :style="{ width: '90%', height: '40%' }"
    >
      <span
        style="
          text-align: center;
          display: block;
          line-height: 33px;
          text-align: center;
          display: block;
          font-size: 14px;
          height: 33px;
        "
        >请填写驳回理由</span
      >
      <van-field
        v-model="bohuiMessage"
        rows="3"
        autosize
        type="textarea"
        maxlength="50"
        placeholder="请输入"
        show-word-limit
      />

      <van-button
        style="
          display: block;
          margin: 0 auto;
          width: 65%;
          height: 44px;
          top: 31px;
        "
        type="primary"
        size="large"
        round
        :disabled="disable"
        @click="bohuiAction"
        >确认驳回</van-button
      >
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { DatacenterContractdetails, TodoAuditprocess } from "../../api";
import { formatDate, roleName } from "../../js/xbTool";
import { Dialog } from "vant";

export default {
  data: function () {
    return {
      contractLogList: [],
      // 是否有效
      contractStatus: "",
      infoContract: {},
      partyA: {},
      partyB: {},
      dataList: ["", "", ""],
      //驳回
      bohuiShow: false,
      bohuiMessage: "",
    };
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    returnContract(int) {
      switch (int) {
        case 1:
          return "框架合同";
        case 2:
          return "单独合同（标准）";
        case 3:
          return "单独合同（非标）";
        case "1":
          return "框架合同";
        case "2":
          return "单独合同（标准）";
        case "3":
          return "单独合同（非标）";
        default:
          break;
      }
    },
    getDataDetails() {
      const that = this;
      DatacenterContractdetails({
        contractid: this.$route.query.id,
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
      })
        .then((result) => {
          if (result.code == 1) {
            that.contractLogList = result.data.contractLogList;
            that.contractStatus = result.data.contractStatus;
            that.infoContract = result.data.infoContract;
            that.partyA = result.data.partyA;
            that.partyB = result.data.partyB;
          }
        })
        .catch((err) => {
          console.log(err);
          Toast("网络错误");
        });
    },
    // 通过
    adoptAction() {
      Dialog.confirm({
        title: "",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: "是否通过该合同",
      })
        .then(() => {
          Toast.loading({
            duration: 0,
            message: "审核中...",
            forbidClick: true,
          });
          TodoAuditprocess({
            approveremark: "审核通过",
            approvestatus: 1,
            auditid: this.$route.query.id,
            audittype: 1,
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
          })
            .then((result) => {
              if (result.code == 1) {
                Toast(result.data);
                this.onClickBack();
              } else {
                Toast(result.data);
              }
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 驳回
    rejectAction() {
      this.bohuiShow = true;
    },
    // 确认驳回
    bohuiAction() {
      if (this.bohuiMessage == "") {
        Toast("请输入驳回理由");
        return;
      }
      Dialog.confirm({
        title: "",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: "是否驳回该合同",
      })
        .then(() => {
          Toast.loading({
            duration: 0,
            message: "驳回中...",
            forbidClick: true,
          });
          TodoAuditprocess({
            approveremark: this.bohuiMessage,
            approvestatus: 2,
            auditid: this.$route.query.id,
            audittype: 1,
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
          })
            .then((result) => {
              if (result.code == 1) {
                Toast(result.data);
                this.onClickBack();
              } else {
                Toast(result.data);
              }
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
    },

    /* method */
    // 电话
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    // 时间转换
    formatDate(item, index) {
      return formatDate(item, index);
    },
    roleName(item) {
      return roleName(item);
    },
  },
  // 获取详情
  mounted: function () {
    console.log(this.$route.query);
    this.getDataDetails();
  },
};
</script>

<style>
/* 基本 */
body::before {
  content: " ";
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(246, 246, 246);
  background-size: 100% auto;
}
.van-nav-bar .van-icon {
  color: black;
}
/* h5 */
h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(48, 48, 48);
  text-align: left;
  /* background-color: clear; */
}

.van-cell-group span {
  color: #323233;
  font-size: 14px;
  height: 33px;
}
.contractClass .van-cell__value span {
  color: rgb(229, 126, 5);
}
.functionBtn {
  width: 40%;
  margin: 20px 5% 10px 5%;
}
</style>
