<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="项目详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="ProjectDetails" style="padding-top: 46px">
    <van-cell-group @click="toDetails">
      <van-cell
        ><span style="color: #e57e05; font-size: 16px"
          >项目单号：{{ this.$route.query.projectCode }}</span
        >
        <br />
        <span v-if="this.$route.query.biNo != null"
          >A10单号：{{ this.$route.query.biNo }}</span
        >
        <span v-if="this.$route.query.biNo == null">A10单号：暂无</span>
        <br />
        <span style="font-size: 16px"
          >项目名称：{{ this.$route.query.projectName }}</span
        >
        <br />
        <span style="font-size: 16px"
          >品尚项目经理：{{ this.$route.query.pmName }}</span
        >
        <br />
        <span style="font-size: 16px"
          >生产项目经理：{{ this.$route.query.plantManagerId }}</span
        >
      </van-cell>
    </van-cell-group>

    <van-cell-group
      v-for="(item, index) in dataList"
      :key="item"
      @click="jump(index + 1, item)"
    >
      <van-cell
        class="acceptance_class"
        :title="returnRectification(item.type)"
      >
        <span
          v-if="item.status == 1"
          style="color: rgb(44, 44, 45); font -size: 16px"
          >{{ returnStatus(index + 1) }} 未开始</span
        >
        <span
          v-if="item.status == 6"
          style="color: rgb(110, 175, 41); font-size: 16px"
          >{{ returnStatus(index + 1) }} 已完成</span
        >
        <span
          v-if="
            item.status == 2 ||
            item.status == 3 ||
            item.status == 4 ||
            item.status == 5 ||
            item.status == 7
          "
          style="color: rgb(244, 175, 41); font-size: 16px"
          >{{ returnStatus(index + 1) }} 未完成</span
        >
      </van-cell></van-cell-group
    >

    <br />
  </div>
</template>

<script>
import { Acceptanceprojdetail, Acceptanceordercreate } from "../../api";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  name: "ProjectDetails",
  data() {
    return {
      projInfo: "",
      infoContract: "",
      infoMarket: "",
      contractStatus: "",
      infoQuoteList: [],
      headUser: [],
      operationManagerUser: [],
      desigrResponsibleUser: [],
      perationResponsibleUser: [],
      plantManagerUser: [],
      typeName: "",
      businessName: "",
      processNode: "",
      projInfoBil: {},
      projContractList: [],

      list: [
        "框架验收",
        "框架验收",
        "出厂验收",
        "出厂验收",
        "安装验收",
        "安装验收",
      ],
      dataList: [],
      checked: "",
      jRindex: 0,
      isR: true,
    };
  },
  methods: {
    toDetails() {
      // console.log(item);
      let listData = {};
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.id = this.$route.query.id;
      // listData.executeStatus = this.getOpenType(item.executeStatus);
      this.$router.push({
        path: "/ProjectDetails",
        query: listData,
      });
    },
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    returnRectification(string) {
      // console.log(string);
      switch (string) {
        case 1:
        case 2:
          return "框架验收";
        case 3:
        case 4:
          return "出厂验收";
        case 5:
        case 6:
          return "安装验收";
        default:
          return "暂无";
      }
    },
    returnbidData(string) {
      return string.slice(0, 10);
    },

    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "" || data === null) {
        return "暂无";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
        );
      }
    },
    returnStatus(status) {
      switch (status) {
        case 1:
          return "自检";
        case 2:
          return "客户检";
        case 3:
          return "自检";
        case 4:
          return "客户检";
        case 5:
          return "自检";
        case 6:
          return "客户检";
        default:
          return "";
      }
    },
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
    getDetails() {
      const that = this;
      Acceptanceprojdetail({
        // token: this.$route.query.token,
        // userid: this.$route.query.userid,
        // projid: this.$route.query.id,
        token: that.$route.query.token,
        userid: that.$route.query.userid,
        projid: that.$route.query.id,
      })
        .then((result) => {
          if (result.code == 1) {
            that.dataList = [];
            var dataArr = result.data;
            that.list.map((item, index) => {
              // 匹配获取的数据
              var isadd = true;
              dataArr.map((item1) => {
                if (index + 1 == item1.type) {
                  isadd = false;
                  that.dataList.push({
                    type: item1.type,
                    status: item1.status,
                    id: item1.id,
                  });
                  return;
                }
              });
              if (isadd) {
                that.dataList.push({
                  type: index + 1,
                  status: 1,
                  id: 0,
                });
              }
            });
            // console.log("最终的");
            // console.log(that.dataList);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    jump(index, item) {
      // console.log(111);
      console.log(item);
      var string = "";
      // return;
      // console.log(status);
      switch (index) {
        case 1:
          string = "框架验收-自检";
          break;
        case 2:
          string = "框架验收-客户检";
          break;
        case 3:
          string = "出厂验收-自检";
          break;
        case 4:
          string = "出厂验收-客户检";
          break;
        case 5:
          string = "安装验收-自检";
          break;
        case 6:
          string = "安装验收-客户检";
          break;
        default:
          break;
      }
      const that = this;
      that.jRindex = index;
      if (item.status == 1) {
        Dialog.confirm({
          title: "",
          confirmButtonText: "是",
          cancelButtonText: "否",
          message: "是否确认开始" + string + "?",
        })
          .then(() => {
            Acceptanceordercreate({
              token: that.$route.query.token,
              userid: that.$route.query.userid,
              projid: that.$route.query.id,
              type: index,
            })
              .then((result) => {
                // console.log(result);
                if (result.code == 1) {
                  console.log(result);
                  let orderId = result.data;
                  var que = {
                    orderId: orderId,
                    projId: that.$route.query.id,
                    type: index,
                    projCode: that.$route.query.projectCode,
                  };
                  // console.log(que);
                  //成功后跳转
                  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                    window.webkit.messageHandlers.toAcceptanceItem.postMessage({
                      title: {
                        orderId: que.orderId,
                        projId: que.projId,
                        type: que.type,
                        projCode: que.projCode,
                      }, //vue给iOS传值
                    });
                  } else {
                    window.android.toAcceptanceItem(
                      que.orderId,
                      que.projId,
                      que.type,
                      que.projCode
                    );
                  }
                  if (that.isR == true) {
                    that.getDetails();
                    that.isR = false;
                  }
                  //
                  // this.getDetails();
                }
              })
              .catch((err) => {
                console.log(err);
                // this.finished = true;
                Toast("网络错误");
              });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        // 传值跳转
        var que = {
          orderId: item.id,
          projId: that.$route.query.id,
          type: item.type,
          projCode: that.$route.query.projectCode,
        };
        console.log(que);
        //成功后跳转
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.toAcceptanceItem.postMessage({
            title: {
              orderId: que.orderId,
              projId: que.projId,
              type: que.type,
              projCode: que.projCode,
            }, //vue给iOS传值
          });
        } else {
          window.android.toAcceptanceItem(
            que.orderId,
            que.projId,
            que.type,
            que.projCode
          );
        }
      }

      // let listData = [];
      // listData.token = this.$route.query.token;
      // listData.userid = this.$route.query.userid;
      // listData.id = id;
      // this.$router.push({
      //   path: "/StoreDetails",
      //   query: listData,
      // });
    },
    // rAcceptanceordercreate() {
    //   var that = this;
    //   Acceptanceordercreate({
    //     token: that.$route.query.token,
    //     userid: that.$route.query.userid,
    //     projid: that.$route.query.id,
    //     type: that.jRindex,
    //   })
    //     .then((result) => {
    //       // console.log(result);
    //       if (result.code == 1) {
    //         console.log(result);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       // this.finished = true;
    //       Toast("网络错误");
    //     });
    // },
  },
  mounted() {
    this.getDetails();
    // location.reload();
    // this.$router.go(0);
  },
  // activated() {
  //   this.getDetails();
  // },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  /* margin-left: 10px; */
}
.van-cell-group {
  margin-top: 10px;
}
.acceptance_class {
  /* background-color: red; */
  height: 68px;
  /* text-align: center; */
  align-items: center;
  font-size: 16px;
  /* justify-content: center; */
}
/* .van-cell__titl {

} */
.cell-group {
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
}
.div-left {
  float: left;
}
.div-right {
  width: 70%;
  float: right;
}
:deep(.van-cell-group span) {
  font-size: 16px;
}
/* .custom-title {
  font-size: 38rpx;
  vertical-align: middle;
} */
</style>
