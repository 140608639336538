<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="回款详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="ReceivableStatisticalDetails" style="padding-top: 46px">
    <br />
    <span
      style="
        text-align: center;
        display: block;
        color: rgb(150, 151, 153);
        font-size: 14px;
      "
      >总金额:{{ this.$route.query.amountTax }}元</span
    >
    <span
      style="
        text-align: center;
        display: block;
        color: rgb(150, 151, 153);
        font-size: 14px;
      "
      >已回款核销金额:{{
        this.$route.query.receivableAmount == null
          ? "0"
          : this.$route.query.receivableAmount
      }}元</span
    >
    <h5>合同信息</h5>
    <van-cell-group
      class="cell-group"
      inset
      v-if="infoContract == null"
      @click="jumpContract(infoContract.id)"
    >
      <van-cell> <span style="font-size: 14px"> 暂无 </span></van-cell>
    </van-cell-group>
    <van-cell-group
      class="cell-group"
      inset
      v-if="infoContract != null"
      @click="jumpContract(infoContract.id)"
    >
      <van-cell
        ><span
          style="color: #e57e05; font-size: 14px"
          v-if="infoContract.contractName != null"
        >
          {{ infoContract.contractName }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="infoContract.type != null">
          {{ returnContract(infoContract.type) }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span
          style="font-size: 14px; margin-left: 0px"
          v-if="infoContract.partybName != null"
        >
          {{ infoContract.partybName }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="infoContract.taxAmount != null">
          {{ infoContract.taxAmount }}元
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="infoContract.minDate != null">
          {{ infoContract.minDate }}至{{ infoContract.maxDate }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="contractStatus != null">
          {{ contractStatus }}
        </span></van-cell
      >
    </van-cell-group>
    <br />
    <h5>项目信息</h5>
    <van-cell-group
      class="cell-group"
      inset
      v-if="infoContract != null"
      @click="jumpProject(projInfo.id)"
    >
      <van-cell
        ><span
          style="color: #e57e05; font-size: 14px"
          v-if="projInfo.projectCode != null"
        >
          【{{ returnType(projInfo.relationFlag) }}】{{ projInfo.projectCode }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="projInfo.projectName != null">
          {{ projInfo.projectName }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span
          style="font-size: 14px; margin-left: 0px"
          v-if="projInfo.projectType != null"
        >
          {{ projInfo.departmentName }}
          {{ projInfo.projectType }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="projInfo.executeStatus != null">
          {{ getOpenType(projInfo.executeStatus) }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="headUser.truename != null">
          品牌负责人:{{ headUser.truename }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span
          style="font-size: 14px; margin-left: 0px"
          v-if="operationManagerUser != null"
        >
          项目经理:{{ operationManagerUser }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span
          style="font-size: 14px; margin-left: 0px"
          v-if="perationResponsibleUser != null"
        >
          运营负责人:{{ perationResponsibleUser }}
        </span></van-cell
      >
      <van-cell style="margin-left: 10px"
        ><span
          style="font-size: 14px; margin-left: 0px"
          v-if="desigrResponsibleUser != null"
        >
          设计负责人:{{ desigrResponsibleUser }}
        </span></van-cell
      >
      <van-cell
        ><span style="font-size: 14px" v-if="projInfo.receiptTime != null">
          下制作单时间:{{ formatDate(projInfo.receiptTime) }}
        </span></van-cell
      >
    </van-cell-group>
    <br />
    <h5>报价信息</h5>
    <van-cell-group inset @click="jumpInvoice(infoQuote)">
      <van-cell v-if="infoQuote == null">暂无</van-cell>
      <van-cell>
        <span
          style="color: #e57e05; font-size: 14px; margin-left: 0px"
          v-if="infoQuote.status == 3"
        >
          【{{ returnStatus(infoQuote.status) }}】{{ infoQuote.po }}
        </span>
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="infoQuote.status != 3"
        >
          【{{ returnStatus(infoQuote.status) }}】{{ infoQuote.po }}
        </span></van-cell
      >
      <van-cell v-if="infoQuote.amountTax != null"
        >税后金额:{{ infoQuote.amountTax }}元</van-cell
      >
      <van-cell v-if="infoQuote.tax != null"
        >税率:{{ infoQuote.tax }}%</van-cell
      >
      <van-cell v-if="infoQuote.amount != null"
        >不含税金额:{{ infoQuote.amount }}元</van-cell
      >
      <van-cell v-if="infoQuote.remark != null"
        >备注:{{ returnNull(infoQuote.remark) }}</van-cell
      >
      <van-cell v-if="infoQuote.remark != null"
        >报价人:{{ returnNull(infoQuote.createUser) }}</van-cell
      >
      <van-cell v-if="infoQuote.remark != null"
        >报价时间:{{ formatDate(infoQuote.createTime) }}</van-cell
      >
      <van-cell v-if="infoQuote.billingReason != null"
        >开票理由:{{ returnNull(infoQuote.billingReason) }}</van-cell
      >
    </van-cell-group>
    <br />
    <h5>发票信息</h5>
    <van-cell-group inset>
      <van-cell v-for="item in invoiceList" :key="item">
        <span
          style="color: #e57e05; font-size: 14px; margin-left: 0px"
          v-if="item.invoiceNum != null"
        >
          发票号:{{ returnNull(item.invoiceNum) }}
        </span>
        <br />
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.invoiceTaxAmount != null"
        >
          发票金额:{{ item.invoiceTaxAmount }}元</span
        >
        <br />
        <span style="font-size: 14px; margin-left: 0px" v-if="item.tax != null">
          税率:{{ item.tax }}%</span
        >
        <br /><span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.invoiceAmount != null"
        >
          不含税金额:{{ item.invoiceAmount }}元</span
        >
        <br /><span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.createTime != null"
        >
          开票人:{{ returnNull(item.createUser) }}</span
        >
        <br /><span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.createTime != null"
        >
          开票时间:{{ item.invoiceDate }}</span
        >
        <br />
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.remark != null"
        >
          备注:{{ returnNull(item.remark) }}</span
        >
        <br />
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.isWriteoff == 0"
        >
          回款核销人:暂无</span
        >
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.isWriteoff == 1"
        >
          回款核销人:{{ returnNull(drawerName) }}</span
        >
        <br />
        <span style="font-size: 14px; margin-left: 0px">
          回款核销时间:{{ returnNull(item.writeoffDate) }}</span
        >
        <br />
        <span style="font-size: 14px; margin-left: 0px"> 状态:</span>
        <span
          style="font-size: 14px; margin-left: 0px; color: red"
          v-if="item.isWriteoff == 0"
        >
          待回款核销</span
        >
        <span
          style="font-size: 14px; margin-left: 0px"
          v-if="item.isWriteoff == 1"
        >
          已回款核销</span
        >
        <br />
      </van-cell>
    </van-cell-group>
    <br />
    <br />
  </div>
</template>

<script>
import { ReceivableStatisticalDetails } from "../../api";
import { Toast } from "vant";
export default {
  name: "ReceivableStatisticalDetails",
  data() {
    return {
      projInfo: "",
      infoContract: "",
      contractStatus: "",
      infoQuote: {},
      headUser: {},
      operationManagerUser: "",
      desigrResponsibleUser: "",
      perationResponsibleUser: "",
      plantManagerUser: "",
      drawerName: "",
      invoiceList: [],
    };
  },
  methods: {
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "" || data === null) {
        return "暂无";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
        );
      }
    },
    returnStatus(status) {
      switch (status) {
        case 1:
          return "未开票";
        case 2:
          return "已申请";
        default:
          return "已开票";
      }
    },
    getOpenType(type) {
      switch (type) {
        case 1:
          return "未开业";
        case 2:
          return "已开业";
        case 3:
          return "整改中";
        case 4:
          return "质保内";
        case 5:
          return "质保外";
        case 6:
          return "已完工";

        default:
          break;
      }
    },

    returnContract(int) {
      switch (int) {
        case 1:
          return "框架合同";
        case 2:
          return "单独合同（标准）";
        case 3:
          return "单独合同（非标）";
        default:
          break;
      }
    },
    returnType(int) {
      switch (int) {
        case 1:
          return "子单";
        case 2:
          return "母单";
        default:
          return "原单";
      }
    },
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
    getDetails() {
      ReceivableStatisticalDetails({
        token: this.$route.query.token,
        userid: this.$route.query.userid,
        invoiceid: this.$route.query.id,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          // if (result.data.length > 0) {
          if (result.code == 1) {
            this.projInfo = result.data.projInfo;
            this.infoContract = result.data.infoContract;
            this.contractStatus = result.data.contractStatus;
            this.infoMarket = result.data.infoMarket;
            this.infoQuote = result.data.infoQuote;
            this.headUser = result.data.headUser;
            this.operationManagerUser = result.data.operationManagerUser;
            this.desigrResponsibleUser = result.data.desigrResponsibleUser;
            this.perationResponsibleUser = result.data.perationResponsibleUser;
            this.invoiceList = result.data.invoiceList;
            this.drawerName = result.data.drawerName;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    jumpProject(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.executeStatus = this.getOpenType(this.projInfo.executeStatus);
      listData.id = id;
      this.$router.push({
        path: "/ProjectDetails",
        query: listData,
      });
    },
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    jumpContract(id) {
      console.log(id);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.contractId = id;
      this.$router.push({
        path: "/ContractDetails",
        query: listData,
      });
    },
    jumpInvoice(item) {
      console.log(item);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.id = item.id;
      listData.amountTax = item.amountTax;
      listData.invoice = "报价";
      if (item.isInvoice == 1) {
        this.$router.push({
          path: "/InvoiceDetails",
          query: listData,
        });
      } else {
        Toast("暂未开票");
      }
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 10px;
}
.cell-group {
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
}
.span-left {
  font-size: 14px;
  margin-left: 0px;
}
.span-right {
  font-size: 14px;
  margin-left: 40px;
  float: right;
}
</style>
