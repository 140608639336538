<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="商场详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="StoreDetails" style="padding-top: 46px">
    <h5>基本信息</h5>
    <van-cell-group inset>
      <van-cell title="商场名称"
        ><span style="color: #e57e05; font-size: 14px; margin-left: 0px">{{
          returnNull(infoMarket.name)
        }}</span></van-cell
      >
      <van-cell
        title="英文名称"
        :value="returnNull(infoMarket.egName)"
        v-if="infoMarket.egName != null"
      />
      <van-cell
        title="商场简称"
        :value="returnNull(infoMarket.shortName)"
        v-if="infoMarket.shortName != null"
      />
      <van-cell
        title="商场电话"
        :value="returnNull(infoMarket.telphone)"
        @click="callPhone(infoMarket.telphone)"
        v-if="infoMarket.telphone != null"
      />
      <van-cell
        title="商场联系人"
        :value="returnNull(infoMarket.contacts)"
        v-if="infoMarket.contacts != null"
      />
      <van-cell
        title="联系人电话"
        :value="returnNull(infoMarket.phone)"
        @click="callPhone(infoMarket.phone)"
        v-if="infoMarket.phone != null"
      />
    </van-cell-group>
    <h5>位置信息</h5>
    <van-cell-group inset>
      <van-cell
        title="所属地区"
        :value="returnNull(infoMarket.regionStr)"
        v-if="infoMarket.regionStr != null"
      />

      <van-cell
        title="商场地址"
        :value="returnNull(infoMarket.address)"
        v-if="infoMarket.address != null"
      />
    </van-cell-group>
    <h5>标签&备注</h5>
    <van-cell-group inset>
      <van-cell v-if="infoMarketLabelList.length > 0">
        <van-tag
          round
          color="#e57e05"
          text-color="white"
          style="
            margin-top: 5px;
            margin-left: 0px;
            margin-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
          "
          v-for="item in infoMarketLabelList"
          :key="item"
          >{{ item.label }}</van-tag
        >
      </van-cell>
      <van-cell
        v-if="infoMarket.remark != null"
        title="备注"
        :value="
          infoMarket.remark == '' || infoMarket.remark == null
            ? '暂无'
            : infoMarket.remark
        "
      />
    </van-cell-group>
    <div v-if="this.$route.query.type == null">
      <br />
    </div>
    <div v-if="this.$route.query.type != null">
      <h5>关联项目</h5>
      <van-cell v-if="projInfoList.length == 0" style="margin-top: 10px">
        暂无
      </van-cell>
      <van-cell
        v-for="item in projInfoList"
        :key="item"
        style="margin-top: 10px"
        @click="jumpProject(item)"
      >
        <span style="color: #e57e05; font-size: 14px; margin-left: 0px"
          >【{{ returnType(item.relationFlag) }}】{{
            returnNull(item.projectCode)
          }}</span
        >
        <br />
        <span style="margin-left: 0px; font-size: 14px">{{
          returnNull(item.projectName)
        }}</span>
      </van-cell>
      <br />
    </div>
  </div>
</template>

<script>
import { TheMallDetails } from "../../api";
import { Toast } from "vant";
export default {
  name: "StoreDetails",
  data() {
    return {
      infoMarket: "",
      infoMarketLabelList: [],
      projInfoList: [],
    };
  },
  methods: {
    returnType(int) {
      switch (int) {
        case 1:
          return "子单";
        case 2:
          return "母单";
        default:
          return "原单";
      }
    },
    getOpenType(type) {
      switch (type) {
        case 1:
          return "未开业";
        case 2:
          return "已开业";
        case 3:
          return "整改中";
        case 4:
          return "质保内";
        case 5:
          return "质保外";
        case 6:
          return "已完工";

        default:
          break;
      }
    },
    jumpProject(item) {
      console.log(item);
      let listData = [];
      listData.token = this.$route.query.token;
      listData.userid = this.$route.query.userid;
      listData.executeStatus = this.getOpenType(item.executeStatus);
      listData.id = item.id;
      this.$router.push({
        path: "/ProjectDetails",
        query: listData,
      });
    },
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    getDetails() {
      TheMallDetails({
        token: this.$route.query.token,
        userid: this.$route.query.userid,
        projid: this.$route.query.id,
        marketid: this.$route.query.id,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          // if (result.data.length > 0) {
          if (result.code == 1) {
            this.infoMarket = result.data.infoMarket;
            this.projInfoList = result.data.projInfoList;
            this.infoMarketLabelList =
              result.data.infoMarket.infoMarketLabelList;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.span-left {
  font-size: 14px;
  margin-left: 0px;
}
.span-right {
  font-size: 14px;
  margin-left: 20px;
  float: right;
}
</style>
