<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    :title="this.$route.query.customerType == 1 ? '品牌客户详情' : '供应商详情'"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="SupplierDetails" style="padding-top: 46px">
    <h5>基本信息</h5>
    <van-cell-group inset>
      <van-cell
        :title="
          this.$route.query.customerType == 1 ? '品牌客户名称' : '供应商名称'
        "
        ><span
          style="color: #e57e05; font-size: 14px; margin-left: 0px"
          v-if="supplierData.name != null"
          >{{ returnNull(supplierData.name) }}</span
        ></van-cell
      >
      <van-cell
        title="合作品牌"
        :value="returnNull(supplierData.brand)"
        v-if="supplierData.brand != null"
      />
      <van-cell
        title="公司官网"
        :value="returnNull(supplierData.officialWebsite)"
        v-if="supplierData.officialWebsite != null"
      />
      <van-cell
        title="业务范围"
        :value="returnNull(supplierData.businessScope)"
        v-if="supplierData.businessScope != null"
      />
      <van-cell
        title="核心竞争力"
        :value="returnNull(supplierData.coreCompetitiveness)"
        v-if="supplierData.coreCompetitiveness != null"
      />
      <van-cell
        title="产品服务领域"
        :value="returnNull(supplierData.serviceArea)"
        v-if="supplierData.serviceArea != null"
      />
      <van-cell
        title="合作伙伴信息"
        :value="returnNull(supplierData.cooperativePartner)"
        v-if="supplierData.cooperativePartner != null"
      />
      <van-cell
        title="未来可合作方向"
        :value="returnNull(supplierData.cooperationDirection)"
        v-if="supplierData.cooperationDirection != null"
      />
    </van-cell-group>
    <h5>联系信息</h5>
    <van-cell-group inset>
      <van-cell
        title="客户姓名"
        :value="returnNull(supplierData.customerName)"
        v-if="supplierData.customerName != null"
      />
      <van-cell
        title="客户联系方式"
        :value="returnNull(supplierData.phone)"
        @click="callPhone(supplierData.phone)"
        v-if="supplierData.phone != null"
      />
      <van-cell
        title="国籍"
        :value="returnNull(supplierData.nationality)"
        v-if="supplierData.nationality != null"
      />
      <van-cell
        title="职务"
        :value="returnNull(supplierData.post)"
        v-if="supplierData.post != null"
      />
      <van-cell
        title="管辖区域或品牌"
        :value="returnNull(supplierData.jurisdiction)"
        v-if="supplierData.jurisdiction != null"
      />
      <van-cell
        title="办公室地址"
        :value="returnNull(supplierData.officeAddress)"
        v-if="supplierData.officeAddress != null"
      />
      <van-cell
        title="办公室座机"
        :value="returnNull(supplierData.officeLandline)"
        @click="callPhone(supplierData.officeLandline)"
        v-if="supplierData.officeLandline != null"
      />
      <van-cell
        title="邮箱"
        :value="returnNull(supplierData.email)"
        v-if="supplierData.email != null"
      />
    </van-cell-group>
    <h5>标签&备注</h5>
    <van-cell-group inset>
      <van-cell v-if="infoSupplierLabelList.length > 0">
        <van-tag
          round
          color="#e57e05"
          text-color="white"
          style="
            margin-top: 5px;
            margin-left: 0px;
            margin-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
          "
          v-for="item in infoSupplierLabelList"
          :key="item"
          >{{ item.label }}</van-tag
        >
      </van-cell>
      <van-cell
        title="备注"
        :value="
          supplierData.remark == '' || supplierData.remark == null
            ? '暂无'
            : supplierData.remark
        "
      />
    </van-cell-group>
    <br />
  </div>
</template>

<script>
import { Suppdetails } from "../../api";
import { Toast } from "vant";
export default {
  name: "SupplierDetails",
  data() {
    return {
      infoSupplierLabelList: [],
      supplierData: "",
    };
  },
  methods: {
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    getDetails() {
      Suppdetails({
        token: this.$route.query.token,
        userid: this.$route.query.userid,
        supplierid: this.$route.query.id,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          // if (result.data.length > 0) {
          if (result.code == 1) {
            this.supplierData = result.data;
            this.infoSupplierLabelList = result.data.infoSupplierLabelList;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 10px;
}
.cell-group {
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
}
.span-left {
  font-size: 14px;
  margin-left: 0px;
}
.span-right {
  font-size: 14px;
  margin-left: 20px;
  float: right;
}
</style>
