// 转换时间-1.天2.秒
export function formatDate(date, formatInt) {
  // 获取单元格数据
  let data = date;
  if (data === "") {
    return "";
  } else {
    let dt = new Date(data);
    if (formatInt == 1) {
      let month =
        dt.getMonth() + 1 < 10
          ? "0" + (dt.getMonth() + 1)
          : "" + (dt.getMonth() + 1);
      let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
      return dt.getFullYear() + "-" + month + "-" + day + " ";
    } else {
      let month =
        dt.getMonth() + 1 < 10
          ? "0" + (dt.getMonth() + 1)
          : "" + (dt.getMonth() + 1);
      let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
      return (
        dt.getFullYear() +
        "-" +
        month +
        "-" +
        day +
        " " +
        (dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours()) +
        ":" +
        (dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes()) +
        ":" +
        (dt.getSeconds() < 10 ? "0" + dt.getSeconds() : dt.getSeconds())
      );
    }
  }
}

/* 返回权限  */
export function roleName(roleId) {
  switch (roleId) {
    case 2:
      return "总经理";
    case 3:
      return "部长";
    case 4:
      return "负责人";
    case 5:
      return "设计师";
    case 6:
      return "项目经理";
    case 7:
      return "运营";
    default:
      break;
  }
}

// export function z_formatDate(date) {
//   return date.format('yyyyMM');
// }
