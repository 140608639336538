<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="使用指南"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="UseGuide" style="padding-top: 46px">
    <van-image
      src="https://images.pexels.com/photos/3222686/pexels-photo-3222686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
    ></van-image>
  </div>
</template>

<script>
export default {
  name: "UseGuide",
  data() {
    return {};
  },
  methods: {
    // 返回
    onClickBack() {
      // 跳转上一级。
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        console.log(222);
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
