<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    :title="this.$route.query.companyType == 1 ? '甲方详情' : '乙方详情'"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="PartyasDetails" style="padding-top: 46px">
    <h5>基本信息</h5>
    <van-cell-group inset>
      <van-cell
        :title="this.$route.query.companyType == 1 ? '甲方名称' : '乙方名称'"
        ><span style="color: #e57e05; font-size: 14px; margin-left: 0px">{{
          "【" +
          companyType(companyInfo.companyType) +
          "】" +
          returnNull(companyInfo.name)
        }}</span></van-cell
      >
      <van-cell
        :title="this.$route.query.companyType == 1 ? '甲方简称' : '乙方简称'"
        :value="returnNull(companyInfo.asname)"
        v-if="companyInfo.asname != null"
      />
      <van-cell
        title="英文名称"
        :value="returnNull(companyInfo.egname)"
        v-if="companyInfo.egname != null"
      />
      <van-cell
        :title="this.$route.query.companyType == 1 ? '甲方电话' : '乙方电话'"
        :value="returnNull(companyInfo.companyTel)"
        @click="callPhone(companyInfo.companyTel)"
        v-if="companyInfo.companyTel != null"
      />
      <van-cell
        :title="
          this.$route.query.companyType == 1 ? '甲方联系人' : '乙方联系人'
        "
        :value="returnNull(companyInfo.contact)"
        v-if="companyInfo.contact != null"
      />
      <van-cell
        title="联系人电话"
        :value="returnNull(companyInfo.phone)"
        @click="callPhone(companyInfo.phone)"
        v-if="companyInfo.phone != null"
      />
    </van-cell-group>
    <h5>银行信息</h5>
    <van-cell-group inset>
      <van-cell
        title="公司税号"
        :value="returnNull(companyInfo.taxNum)"
        v-if="companyInfo.taxNum != null"
      />
      <van-cell
        title="开户银行"
        :value="returnNull(companyInfo.depositBank)"
        v-if="companyInfo.depositBank != null"
      />
      <van-cell
        title="银行账号"
        :value="returnNull(companyInfo.accountNumber)"
        v-if="companyInfo.accountNumber != null"
      />
    </van-cell-group>
    <h5>位置信息</h5>
    <van-cell-group inset>
      <van-cell
        title="具体地址"
        :value="returnNull(companyInfo.address)"
        v-if="companyInfo.address != null"
      />
    </van-cell-group>
    <br />
    <span style="font-weight: bold; margin-left: 15px">备注</span>
    <van-cell-group inset>
      <van-cell
        title="备注"
        :value="
          companyInfo.remark == '' || companyInfo.remark == null
            ? '暂无'
            : companyInfo.remark
        "
      />
    </van-cell-group>
    <br />
  </div>
</template>

<script>
import { CompanyDetails } from "../../api";
import { Toast } from "vant";
export default {
  name: "PartyasDetails",
  data() {
    return {
      companyInfo: "",
    };
  },
  methods: {
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    companyType(int) {
      switch (int) {
        case 1:
          return "甲方";
        case 2:
          return "乙方";
        default:
          return "商场";
      }
    },
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    getDetails() {
      CompanyDetails({
        token: this.$route.query.token,
        userid: this.$route.query.userid,
        companyid: this.$route.query.id,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          // if (result.data.length > 0) {
          if (result.code == 1) {
            this.companyInfo = result.data;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 10px;
}
.cell-group {
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
}
.span-left {
  font-size: 14px;
  margin-left: 0px;
}
.span-right {
  font-size: 14px;
  margin-left: 20px;
  float: right;
}
</style>
