<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="合同详情"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="ContractDetails" style="padding-top: 46px">
    <h5>基本信息</h5>
    <van-cell-group inset>
      <van-cell title="合同名称"
        ><span style="color: #e57e05; font-size: 14px; margin-left: 0px">{{
          infoContract.contractName
        }}</span></van-cell
      >
      <van-cell title="合同类型" :value="returnContract(infoContract.type)" />
      <van-cell title="是否有效" :value="contractStatus" />
      <van-cell
        title="合同金额"
        :value="infoContract.taxAmount + '元'"
        v-if="infoContract.taxAmount != null"
      />
      <van-cell
        title="税率 "
        :value="infoContract.taxRate + '%'"
        v-if="infoContract.taxRate != null"
      />
      <van-cell
        title="不含税金额"
        :value="infoContract.amount + '元'"
        v-if="infoContract.amount != null"
      />
      <van-cell
        title="回款条件"
        :value="returnNull(infoContract.backCondition)"
        v-if="infoContract.backCondition != null"
      />
      <van-cell
        title="签订日期"
        :value="returnNull(infoContract.signDate)"
        v-if="infoContract.signDate != null"
      />
      <van-cell
        title="有效期"
        :value="infoContract.minDate + '至' + infoContract.maxDate"
        v-if="infoContract.minDate != null"
      />
      <van-cell
        title="存档归属地"
        :value="infoContract.filingTerritory"
        v-if="infoContract.filingTerritory != null"
      />
      <van-cell
        title="登记人"
        :value="returnNull(infoContract.createUser)"
        v-if="infoContract.createUser != null"
      />
      <van-cell
        title="登记日期"
        :value="formatDate(infoContract.createTime)"
        v-if="infoContract.createTime != null"
      />
    </van-cell-group>
    <h5>甲方信息</h5>
    <van-cell-group inset>
      <van-cell
        title="甲方名称"
        :value="returnNull(partyA.name)"
        v-if="partyA.name != null"
      />
      <van-cell
        title="公司税号"
        :value="returnNull(partyA.taxNum)"
        v-if="partyA.taxNum != null"
      />
      <van-cell
        title="开户银行"
        :value="returnNull(partyA.depositBank)"
        v-if="partyA.depositBank != null"
      />
      <van-cell
        title="银行账号"
        :value="returnNull(partyA.accountNumber)"
        v-if="partyA.accountNumber != null"
      />
      <van-cell
        title="甲方地址"
        :value="returnNull(partyA.address)"
        v-if="partyA.address != null"
      />
      <van-cell
        title="甲方联系人"
        :value="returnNull(partyA.contact)"
        v-if="partyA.contact != null"
      />
      <van-cell
        title="联系人电话"
        :value="partyA.phone"
        @click="callPhone(partyA.phone)"
        v-if="partyA.phone != null"
      />
    </van-cell-group>
    <h5>乙方信息</h5>
    <van-cell-group inset>
      <van-cell
        title="乙方名称"
        :value="returnNull(partyB.name)"
        v-if="partyB.name != null"
      />
      <van-cell
        title="公司税号"
        :value="returnNull(partyB.taxNum)"
        v-if="partyB.taxNum != null"
      />
      <van-cell
        title="开户银行"
        :value="returnNull(partyB.depositBank)"
        v-if="partyB.depositBank != null"
      />
      <van-cell
        title="银行账号"
        :value="returnNull(partyB.accountNumber)"
        v-if="partyB.accountNumber != null"
      />
      <van-cell
        title="乙方地址"
        :value="returnNull(partyB.address)"
        v-if="partyB.address != null"
      />
      <van-cell
        title="乙方联系人"
        :value="returnNull(partyB.contact)"
        v-if="partyB.contact != null"
      />
      <van-cell
        title="联系人电话"
        :value="partyB.phone"
        @click="callPhone(partyB.phone)"
        v-if="partyB.phone != null"
      />
    </van-cell-group>
    <h5>备注</h5>
    <van-cell-group inset>
      <van-cell
        title="备注"
        :value="
          infoContract.remark == '' || infoContract.remark == null
            ? '暂无'
            : infoContract.remark
        "
      />
    </van-cell-group>
    <br />
  </div>
</template>

<script>
import { ConDetails } from "../../api";
import { Toast } from "vant";
export default {
  name: "ContractDetails",
  data() {
    return {
      infoContract: "",
      contractStatus: "",
      partyA: {},
      partyB: {},
    };
  },
  methods: {
    callPhone(phone) {
      if (phone == null || phone == "" || phone == "暂无") {
        Toast("电话号码为空");
        return;
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
        );
      }
    },
    returnContract(int) {
      switch (int) {
        case 1:
          return "框架合同";
        case 2:
          return "单独合同（标准）";
        case 3:
          return "单独合同（非标）";
        default:
          break;
      }
    },
    returnNull(string) {
      if (string == null || string == "") {
        return "暂无";
      } else {
        return string;
      }
    },
    getDetails() {
      ConDetails({
        token: this.$route.query.token,
        userid: this.$route.query.userid,
        contractid: this.$route.query.contractId,
      })
        .then((result) => {
          // this.columns.push(...result.data);
          // 循环创建iamgeList
          // if (result.data.length > 0) {
          if (result.code == 1) {
            this.infoContract = result.data.infoContract;
            this.contractStatus = result.data.contractStatus;
            this.partyA = result.data.partyA;
            this.partyB = result.data.partyB;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    onClickBack() {
      // 跳转上一级。
      this.$router.back();
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
span {
  font-size: 12px;
  margin-left: 10px;
}
.van-cell-group {
  margin-top: 10px;
}
.cell-group {
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px;
}
.span-left {
  font-size: 14px;
  margin-left: 0px;
}
.span-right {
  font-size: 14px;
  margin-left: 20px;
  float: right;
}
</style>
