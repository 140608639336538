<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="发票信息"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="InvoiceInformation" style="padding-top: 46px">
    <van-search
      background="#f3f3f3"
      v-model="search"
      placeholder="请输入合同名称、项目单号或项目名称搜索"
      @search="onSearch"
    >
    </van-search>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div v-show="total > 0">
          <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >数量合计:{{ total }}</span
          >
          <span
            style="
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >发票金额合计:{{ moneytotal }}元</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <div>
            <span style="color: #e57e05">发票金额:{{ item.amountTax }}元</span>
            <br />
            <span>关联合同:{{ item.contractName }}</span>
            <br />
            <span>关联项目:{{ item.projectCode }} {{ item.projectName }}</span>
            <br />
            <span>关联PO号:{{ item.quotePo }}</span>
            <br />
            <span>开票时间:{{ formatDate(item.createTime) }}</span>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { useRoute } from "vue-router";
import { InvoInformation } from "../../api";
export default {
  name: "InvoiceInformation",
  setup() {
    let route = useRoute();
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const token = ref(route.query.token);
    const userid = ref(route.query.userid);
    const total = ref("");
    const moneytotal = ref(0);
    const search = ref("");
    const screening = ref("");
    const onSearch = (val) => {
      pageNum.value = 1;
      total.value = 0;
      search.value = val;
      list.value = [];
      onLoad();
    };
    const onConfirm = (value, index) => {
      screening.value = index + 2;
      pageNum.value = 1;
      console.log(search.value + screening.value + "," + index);
      list.value = [];
      onLoad();
    };
    const onLoad = () => {
      InvoInformation({
        token: route.query[0],
        userid: route.query[1],
        roleid: route.query[3],
        findfactor: search.value,
        page: pageNum.value,
        size: 20,
      })
        .then((result) => {
          if (result.data == null) {
            total.value = 0;
            Toast(result.message);
          }
          if (refreshing.value) {
            list.value = [];
            pageNum.value = 1;
            refreshing.value = false;
          }
          if (result.data != null) {
            list.value.push(...result.data.infoInvoiceList.list);
            if (result.data.infoInvoiceList.total == 0) {
              total.value = result.data.infoInvoiceList.list.length;
            } else {
              total.value = result.data.infoInvoiceList.total;
              moneytotal.value = result.data.amountAll;
            }

            loading.value = false;
            if (result.data.hasNextPage) {
              pageNum.value = result.data.nextPage;
            } else {
              finished.value = true;
            }
          } else {
            finished.value = true;
          }
        })
        .catch((err) => {
          Toast("网络错误");
          console.log(err);
          error.value = true;
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      search,
      onSearch,
      screening,
      onConfirm,
      list,
      onLoad,
      onRefresh,
      loading,
      refreshing,
      finished,
      token,
      userid,
      pageNum,
      total,
      moneytotal,
    };
  },
  methods: {
    // 返回
    onClickBack() {
      // 跳转上一级。
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
        );
      }
    },
    jump(item) {
      console.log(item + "ssas");
      let listData = item;
      listData.token = this.$route.query[0];
      listData.userid = this.$route.query[1];
      this.$router.push({
        path: "/InvoiceDetails",
        query: listData,
      });
    },
  },
};
</script>

<style scoped>
:deep(.van-nav-bar__arrow) {
  color: black !important;
}
:deep(.van-nav-bar__title) {
  color: black !important;
}
.van-search {
  background: #f3f3f3;
}
</style>
