<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="合同审核"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="main" style="padding-top: 46px"></div>
  <van-search
    background="#f3f3f3"
    v-model="searchValue"
    placeholder="请输入合同名称搜索"
    @search="onSearch"
  />
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      v-model:error="error"
      error-text="请求失败，点击重新加载"
      @load="getDataList"
    >
      <div v-show="amountAll > 0">
        <span
          style="
            text-align: center;
            display: block;
            font-size: 14px;
            height: 30px;
            padding-top: 5px;
            color: rgb(151, 152, 154);
          "
          >合同合计:{{ amountAll }}</span
        >
      </div>
      <van-cell v-for="item in list" :key="item" @click="jump(item)">
        <span style="color: rgb(229, 126, 5)">{{ item.contractName }}</span>
        <br />
        <span v-if="item.type == 1">框架合同</span>
        <span v-if="item.type == 2">单独合同(标准)</span>
        <span v-if="item.type == 3">单独合同(非标)</span>
        <span v-if="item.type == 4">开票</span>
        <span v-if="item.type == 5">支出</span>
        <br />
        <span>{{ item.partyaName }}</span>
        <br />
        <span>{{ item.taxAmount }}元</span>
        <br />
        <span>{{ item.minDate }}至{{ item.maxDate }}</span>
        <br />
        <span style="color: rgb(229, 126, 5)" v-if="item.status == 1"
          >待审核</span
        >
        <span style="color: rgb(229, 126, 5)" v-if="item.status == 2"
          >审核中</span
        >
        <span style="color: rgb(229, 126, 5)" v-if="item.status == 3"
          >已驳回</span
        >
        <span style="color: rgb(229, 126, 5)" v-if="item.status == 4"
          >已通过</span
        >
      </van-cell>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { TodoExamineContractList } from "../../api";
import { formatDate } from "../../js/xbTool";

export default {
  setup() {
    const searchValue = ref("");
    return { searchValue };
  },
  data: function () {
    return {
      list: [],
      loading: false,
      finished: false,
      error: false,
      refreshing: false,
      amountAll: 0,
      page: 1,
    };
  },
  methods: {
    // 列表
    getDataList() {
      TodoExamineContractList({
        findfactor: this.searchValue,
        page: this.page,
        size: 10,
        token: this.$route.query[0],
        userid: this.$route.query[1],
      })
        .then((result) => {
          if (this.page == 1) {
            this.list = [];
          }
          if (result.data == null) {
            this.amountAll = 0;
            Toast(result.message);
          } else {
            this.refreshing = false;
            this.loading = false;
            if (result.data.hasNextPage) {
              this.finished = false;
              this.page = result.data.nextPage;
            } else {
              this.finished = true;
            }
            if (result.code == 1) {
              this.amountAll = result.data.total;
              // // 将新数据与老数据进行合并
              this.list.push(...result.data.list);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.amountAll = 0;
          // Toast("网络错误");
        });
    },
    onRefresh() {
      // 上拉加载
      setTimeout(() => {
        this.amountAll = 0;
        this.loading = false;
        this.finished = false;
        this.page = 1;
        this.getDataList();
      }, 1000);
    },
    // 搜索
    onSearch() {
      this.amountAll = 0;
      this.loading = false;
      this.finished = true;
      this.page = 1;
      this.getDataList();
    },
    // 时间转换
    formatDate(item) {
      return formatDate(item, 1);
    },
    // 跳转
    jump(item) {
      let itemData = item;
      itemData.z_userid = this.$route.query[1];
      itemData.z_token = this.$route.query[0];
      this.$router.push({
        path: "/ContractReviewDetails",
        query: itemData,
      });
    },
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
  },
  // // 获取
  // mounted: function () {
  //   this.getDataList();
  // },
};
</script>

<style>
body::before {
  content: " ";
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(246, 246, 246);
  background-size: 100% auto;
}
.van-nav-bar .van-icon {
  color: black;
}
</style>
