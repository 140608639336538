<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="发票审核"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <div class="main" style="padding-top: 46px"></div>
  <van-search
    background="#f3f3f3"
    v-model="searchValue"
    placeholder="请输入合同名称、项目单号或项目名称搜索"
    @search="onSearch"
  />
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      v-model:error="error"
      error-text="请求失败，点击重新加载"
      @load="onLoad"
    >
      <div v-show="amountAll > 0">
        <span
          style="
            text-align: center;
            display: block;
            font-size: 14px;
            height: auto;
            padding-top: 5px;
            color: rgb(151, 152, 154);
          "
          >数量合计:{{ total }}</span
        >
        <span
          style="
            text-align: center;
            display: block;
            font-size: 14px;
            height: 30px;
            padding-top: 5px;
            color: rgb(151, 152, 154);
          "
          >开票金额合计:{{ amountAll }}元</span
        >
      </div>
      <van-cell v-for="item in list" :key="item" @click="jump(item)">
        <span style="color: rgb(229, 126, 5)">{{
          "发票金额:" + item.amountTax + "元"
        }}</span>
        <br />
        <span>{{ "关联合同:" + item.contractName }}</span>
        <br />
        <span>{{ "关联项目:" + item.projectName }}</span>
        <br />
        <span>{{ "开票时间:" + formatDate(item.createTime, 1) }}</span>
        <br />
        <span style="color: rgb(229, 126, 5)" v-if="item.status == 1"
          >待审核</span
        >
        <span style="color: rgb(229, 126, 5)" v-if="item.status == 2"
          >审核中</span
        >
        <span style="color: rgb(229, 126, 5)" v-if="item.status == 3"
          >已驳回</span
        >
        <span style="color: rgb(229, 126, 5)" v-if="item.status == 4"
          >已通过</span
        >
      </van-cell>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import { ref } from "vue";
// import { Toast } from "vant";
import { TodoTodoexamineinvoicelist } from "../../api";
import { formatDate } from "../../js/xbTool";
import { Toast } from "vant";

export default {
  setup() {
    const searchValue = ref("");
    return { searchValue };
  },
  data: function () {
    return {
      list: [],
      loading: false,
      finished: false,
      error: false,
      refreshing: false,
      amountAll: 0,
      total: 0,
      page: 1,
    };
  },
  methods: {
    // 列表
    getDataList() {
      TodoTodoexamineinvoicelist({
        findfactor: this.searchValue,
        page: this.page,
        size: 10,
        token: this.$route.query[0],
        userid: this.$route.query[1],
      })
        .then((result) => {
          if (result.data == null) {
            this.total = 0;
            this.amountAll = 0;
            Toast(result.message);
          }
          this.refreshing = false;
          this.loading = false;
          if (result.data.hasNextPage) {
            this.page = result.data.infoInvoiceList.nextPage;
          } else {
            this.finished = true;
          }
          if (result.code == 1) {
            this.amountAll = result.data.amountAll;
            this.total = result.data.infoInvoiceList.total;
            // 将新数据与老数据进行合并
            this.list = [...this.list, ...result.data.infoInvoiceList.list];
          }
        })
        .catch((err) => {
          console.log(err);
          // Toast("网络错误");
        });
    },
    onRefresh() {
      // 上拉加载
      setTimeout(() => {
        // Toast("刷新成功");
        this.loading = false;
        this.list = [];
        this.page = 1;
        this.getDataList();
      }, 1000);
    },
    // 搜索
    onSearch() {
      this.onRefresh();
    },
    // 时间转换
    formatDate(item, index) {
      return formatDate(item, index);
    },
    // 跳转
    jump(item) {
      let itemData = item;
      itemData.z_userid = this.$route.query[1];
      itemData.z_token = this.$route.query[0];
      this.$router.push({
        path: "/InvoiceReviewDetails",
        query: itemData,
      });
    },
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
  },
  // 获取
  mounted: function () {
    this.getDataList();
  },
};
</script>

<style>
body::before {
  content: " ";
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(246, 246, 246);
  background-size: 100% auto;
}
.van-nav-bar .van-icon {
  color: black;
}
</style>
