// 刚刚封装的axios
import request from "./axios";
import Qs from "qs"; // 传参需要用qs转换一下

// 考勤看板
export const AttenceBoard = (query) => {
  return request({
    url: "/watchdata/attenceboard",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 项目看板
export const ProjBoard = (query) => {
  return request({
    url: "/watchdata/projectboard",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 财务看板
export const FinanBoard = (query) => {
  return request({
    url: "/watchdata/financialboard",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 项目信息
export const ProjectInformation = (query) => {
  return request({
    url: "/datacenter/projectinformation",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 项目详情
export const ProjDetails = (query) => {
  return request({
    url: "/datacenter/projectdetails",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 商场信息
export const TheMallInformation = (query) => {
  return request({
    url: "/datacenter/themallinformation",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 商场详情
export const TheMallDetails = (query) => {
  return request({
    url: "/datacenter/themalldetails",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 商场筛选条件
export const Region = (query) => {
  return request({
    url: "/datacenter/region",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 供应商信息
export const SuppInformation = (query) => {
  return request({
    url: "/datacenter/supplierinformation",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 供应商详情
export const Suppdetails = (query) => {
  return request({
    url: "/datacenter/supplierdetails",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 甲方信息
export const FirstPartyInformation = (query) => {
  return request({
    url: "/datacenter/firstpartyinformation",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 乙方信息
export const SecondPartyInformation = (query) => {
  return request({
    url: "/datacenter/secondpartyinformation",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 甲乙方详情
export const CompanyDetails = (query) => {
  return request({
    url: "/datacenter/companydetails",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 合同信息
export const ConInformation = (query) => {
  return request({
    url: "/datacenter/contractinformation",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 合同详情
export const ConDetails = (query) => {
  return request({
    url: "/datacenter/contractdetails",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 发票信息
export const InvoInformation = (query) => {
  return request({
    url: "/datacenter/invoiceinformation",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 发票信息
export const InvoDetails = (query) => {
  return request({
    url: "/datacenter/invoicedetails",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 发票信息
export const ReceivableStatistical = (query) => {
  return request({
    url: "/reportcenter/receivablestatistical",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 详情
export const ReceivableStatisticalDetails = (query) => {
  return request({
    url: "/reportcenter/receivablestatisticaldetails",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 合同审核列表
export const TodoExamineContractList = (query) => {
  return request({
    url: "/todo/todoexaminecontractlist",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 合同详情
export const DatacenterContractdetails = (query) => {
  return request({
    url: "/datacenter/contractdetails",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 合同、发票、支出 审核
export const TodoAuditprocess = (query) => {
  return request({
    url: "/todo/auditprocess",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 支出审核列表
export const TodoExamincostList = (query) => {
  return request({
    url: "/todo/todoexaminecostlist",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 支出详情
export const ReportcenterCostdetail = (query) => {
  return request({
    url: "/reportcenter/costdetail",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 发票审核列表
export const TodoTodoexamineinvoicelist = (query) => {
  return request({
    url: "/todo/todoexamineinvoicelist",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 发票详情
export const DatacenterInvoicedetails = (query) => {
  return request({
    url: "/datacenter/invoicedetails",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 支出统计
export const ReportcenterCoststatistical = (query) => {
  return request({
    url: "/reportcenter/coststatistical",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 支出统计
export const Acceptanceprojdetail = (query) => {
  return request({
    url: "/acceptance/acceptanceprojdetail",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 项目验收列表
export const AcceptanceProjlist = (query) => {
  return request({
    url: "/acceptance/acceptanceprojlist",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 创建验收单
export const Acceptanceordercreate = (query) => {
  return request({
    url: "/acceptance/acceptanceordercreate",
    method: "post",
    data: Qs.stringify(query),
  });
};
